import { useService, useQuery } from '@hooks';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { compact } from '@utils';
import type { GLOBAL_SEARCH_DEFINITION } from '@constants';
import type { TSearchHitDocument } from '@typings';

export const useSearchResultByDefinition = (
	searchQuery: string,
	definition: keyof typeof GLOBAL_SEARCH_DEFINITION,
	nbHits: number,
) => {
	const api = useService('AlgoliaSearchService');
	const { enabled: isDealsEnabled } = useDealsEnabled();

	const {
		data: searchResultByIndex,
		refetch,
		isLoading,
	} = useQuery(
		['searchByIndex', definition],
		async () => {
			if (definition === 'calendar') {
				const calendarItems = await Promise.all([
					api.searchByIndex(searchQuery, 'event', nbHits),
					api.searchByIndex(searchQuery, 'meeting', nbHits),
				]);
				return calendarItems.flat();
			} else if (definition === 'document') {
				const documentHits = (await Promise.all(
					compact([
						api.searchByIndex(searchQuery, 'communityDocuments', nbHits),
						api.searchByIndex(searchQuery, 'calendarDocuments', nbHits),
						isDealsEnabled ? api.searchByIndex(searchQuery, 'dealDocuments', nbHits) : Promise.resolve([]),
						api.searchByIndex(searchQuery, 'networkConnectionDocuments', nbHits),
					]),
				)) as TSearchHitDocument[][];
				return api.normalizeDocumentSearchHits(documentHits);
			} else {
				return await api.searchByIndex(searchQuery, definition, nbHits);
			}
		},
		{
			enabled: false,
		},
	);

	return { searchResultByIndex, refetch, isLoading };
};
