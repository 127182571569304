import type { MouseEventHandler } from 'react';
import { useContext, useState } from 'react';
import { AvatarIcon, UserInActiveIcon } from '../../icons';
import { UikitContext } from '../../contexts/UikitContext';
import { clsx, noop } from '@utils';
import styles from './Avatar.module.css';
import { getAvatarInitials, toString } from '@utils';
import { findClosestSize, getLabelSize } from './Avatar.utils';

const Avatar = ({
	imageUri,
	source,
	title,
	size,
	placeholderColor,
	outline = false,
	isInactive = false,
	className,
	containerClassName,
	onClick,
}: IAvatarProps) => {
	const { avatarBaseUri } = useContext(UikitContext);

	const compiledTitle = getAvatarInitials(toString(title));
	const closestSize = findClosestSize(size);
	const [imageLoadingError, setImageLoadingError] = useState(false);
	const fallbackUri = compiledTitle ? `${avatarBaseUri}/${compiledTitle}-${closestSize}.png` : undefined;
	const finalSource = imageUri ? imageUri : fallbackUri ? fallbackUri : undefined;
	const isSmallImage = size <= 40;

	if (isInactive) return <UserInActiveIcon width={size} />;
	return (
		<div className={className} onClick={onClick} onKeyPress={noop}>
			<div
				className={clsx(
					styles.avatar__container,
					outline && styles.avatar__image_outline,
					outline && isSmallImage && styles.avatar__image_outlineSmall,
					outline && !isSmallImage && styles.avatar__image_outlineBig,
					containerClassName,
				)}
			>
				{(source || finalSource) && !imageLoadingError ? (
					<img
						alt="user_avatar"
						className={styles.avatar__image}
						src={source || finalSource}
						style={{ width: size, height: size }}
						title={compiledTitle}
						onError={() => setImageLoadingError(true)}
					/>
				) : (
					<div
						className={clsx(styles.avatar__noImage)}
						style={{ width: size, height: size, borderRadius: size / 2, backgroundColor: placeholderColor }}
					>
						{compiledTitle ? (
							<span className={styles.avatar__label} style={{ fontSize: getLabelSize(size) }}>
								{compiledTitle}
							</span>
						) : (
							<AvatarIcon color="white" height={size} width={size} />
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export interface IAvatarProps {
	size: number;
	imageUri?: string | null | undefined;
	source?: string;
	title?: string;
	placeholderColor?: string;
	outline: boolean;
	isInactive?: boolean;
	className?: string;
	containerClassName?: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
}

export default Avatar;
