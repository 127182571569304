import NotificationCard from '../NotificationCard';
import { BellSlashIcon, DeleteIcon } from '@ui-kit';
import { useMemo, useCallback } from '@hooks';
import { useState, useTranslation } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import type { INotificationFeedActivityGroup, INotificationFeedActivity } from '@typings';

import styles from './NotificationListItem.module.css';

const NotificationsListItem = ({ activityGroup, onClick }: INotificationListItemProps) => {
	const { t } = useTranslation();
	const { onMarkAsRead, onRemoveActivity } = useFeedContext();
	const [isItemVisible, setIsItemVisible] = useState(true);

	const activities = activityGroup.activities;
	const notification = activities[0];
	const isReply = activityGroup.verb === 'replied';
	const isDeal = activityGroup.verb === 'replied_on_deal';
	const isNetworkConnectionReply = activityGroup.verb === 'network_connection_reply_created';

	const onRemove = useCallback(() => {
		setIsItemVisible(false);
		return Promise.all(activities.map((activity) => onRemoveActivity(activity.id)));
	}, [activities, onRemoveActivity]);

	const menuOptions = useMemo(() => {
		if (activityGroup.is_read) {
			return [
				{
					text: t('Delete'),
					icon: <DeleteIcon className={styles.notificationListItem__icon} height={20} width={18} />,
					onClick: () => onRemove(),
				},
			];
		}
		return [
			{
				text: t('Mark as Read'),
				icon: <BellSlashIcon className={styles.notificationListItem__icon} height={20} width={25} />,
				onClick: () => onMarkAsRead(activityGroup),
			},
			{
				text: t('Delete'),
				icon: <DeleteIcon className={styles.notificationListItem__icon} height={20} width={18} />,
				onClick: () => onRemove(),
			},
		];
	}, [activityGroup, onRemove, onMarkAsRead, t]);

	const onClickNotification = useCallback(() => {
		onMarkAsRead(activityGroup);
		onClick(notification);
	}, [notification, onClick, onMarkAsRead, activityGroup]);

	if (!isItemVisible) return null;

	return (
		<NotificationCard
			activities={activities}
			isDeal={isDeal}
			isNetworkConnectionReply={isNetworkConnectionReply}
			isReply={isReply}
			isUnread={!activityGroup.is_read}
			menuOptions={menuOptions}
			notification={notification}
			onClick={onClickNotification}
		/>
	);
};

export interface INotificationListItemProps {
	activityGroup: INotificationFeedActivityGroup;
	onClick: (notification: INotificationFeedActivity) => void;
}

export default NotificationsListItem;
