import { clsx, NetworkConnectionEntity } from '@utils';
import { ROUTES } from '@constants';
import { useNavigate, useRef, useTranslation } from '@hooks';
import type { TMeatballMenuOption } from '@ui-kit';
import { BlockLink, DynamicFieldDataGrid, HighlightedText, Post, When, DynamicLongTextSection } from '@ui-kit';

import styles from './NetworkConnectionPost.module.css';

import type { RefObject } from 'react';
import { useMemo } from 'react';
import type { TCommunity, TNetworkConnectionWithReactions } from '@typings';
import { useState } from 'reinspect';

const NetworkConnectionPost = ({
	networkConnection,
	currentNetworkId,
	highlightedText,
	restoreScrollRoute,
	likingDisabled = false,
	previewMode = false,
	meatballMenuOptions = [],
	onLike,
	onToggleAlerts,
	onClick,
	onTextCollapse,
}: INetworkConnectionPostProps) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const networkConnectionPostRef = useRef<HTMLElement>() as RefObject<HTMLElement>;

	const [alerts, setAlerts] = useState(true);

	const dynamicFields = useMemo(
		() =>
			NetworkConnectionEntity.extractDynamicFieldsDataV2(networkConnection, {
				currency: t('Currency'),
			}).filter((field) => field.label !== t('Currency')), // T21C-6844 hide currency field only in NC post
		[networkConnection],
	);

	const [categories, subcategories] = useMemo(() => {
		const { categories, subcategories } = NetworkConnectionEntity.extractCategoriesAndSubcategories(
			networkConnection.categories,
		);
		return [categories.join(', '), subcategories.join(', ')];
	}, [networkConnection.categories]);

	const longTextFields = useMemo(
		() => NetworkConnectionEntity.extractLongTextFields(networkConnection),
		[networkConnection],
	);

	const handleClickDiscussions = () => {
		onClick?.();
		navigate(ROUTES.networkConnection(networkConnection.id, 'discussions'), { state: { restoreScrollRoute } });
	};

	return (
		<Post
			bodyClassName={styles.networkConnectionPost__body}
			footerElement={
				<When condition={!previewMode}>
					<Post.PostActionBar
						alerts={alerts}
						// TODO implement
						// alertsTitle={t('Alerts {{condition}}', { condition: alerts ? t('Off') : t('On') })}
						comments={networkConnection.commentsCount}
						hasMyLike={!!networkConnection.ownLikes?.length}
						likes={networkConnection.likesCount}
						likeTitle={t('Like')}
						likingDisabled={likingDisabled}
						replyTitle={t('Reply')}
						onClickAlerts={(alerts) => {
							// TODO implement alerts here
							setAlerts(alerts);
						}}
						onClickComment={handleClickDiscussions}
						onClickReply={handleClickDiscussions}
						onLike={(newHasMyLike) => onLike(networkConnection, newHasMyLike)}
					/>
				</When>
			}
			headerElement={
				<>
					<Post.Header
						avatarProps={{
							imageUri: networkConnection.owner.avatarUrl,
							outline: false,
							size: 40,
						}}
						createdAt={networkConnection.createdAt}
						subtitle={NetworkConnectionEntity.getParentCategoryNamesString(networkConnection)}
						userId={networkConnection.owner.id}
						userName={networkConnection.owner.fullName}
						onClick={onClick}
						onProfileClicked={() => {
							if (networkConnection.owner.roles.includes('ROLE_INACTIVE')) return;
							navigate(ROUTES.memberProfile(networkConnection.owner.profileId));
						}}
					/>
				</>
			}
			isCard
			meatballMenuOptions={previewMode ? undefined : meatballMenuOptions}
			navigationState={{ state: { restoreScrollRoute } }}
			postLink={ROUTES.networkConnection(networkConnection.id, 'details')}
			ref={networkConnectionPostRef}
			withFooterSeparator={!previewMode}
			wrapperClassName={styles.networkConnectionPost}
			onClick={onClick}
		>
			<BlockLink
				className={styles.networkConnectionPost__bodyContent}
				navigationState={{ state: { restoreScrollRoute } }}
				to={ROUTES.networkConnection(networkConnection.id, 'details')}
				onClick={onClick}
			>
				<HighlightedText className={styles.networkConnectionPost__title} highlightedText={highlightedText}>
					{networkConnection.title}
				</HighlightedText>

				<When condition={!!categories}>
					<div>
						<span className={clsx(styles.networkConnectionPost__textTitle)}>{t('Category')}</span>
						<span>{categories}</span>
					</div>
				</When>

				<When condition={!!subcategories}>
					<div>
						<span className={styles.networkConnectionPost__textTitle}>{t('Subcat')}</span>
						<span>{subcategories}</span>
					</div>
				</When>

				{!!dynamicFields?.length && (
					<div>
						<DynamicFieldDataGrid
							booleanFieldFalseLabel={t('No')}
							booleanFieldTrueLabel={t('Yes')}
							currency={networkConnection.currency}
							dynamicFields={dynamicFields}
							highlightedText={highlightedText}
							uniqueFieldStyle={dynamicFieldStyle}
						/>
					</div>
				)}
				{networkConnection?.description ? (
					<div>
						<span className={styles.networkConnectionPost__textTitle}>{t('Description')}</span>
						<Post.Content
							className={styles.networkConnectionPost__content}
							collapsedNumberOfLines={3}
							collapsedTitle={t('Read more')}
							expandedTitle={t('Show less')}
							highlightingText={highlightedText}
							toggleHidden={previewMode}
							onCollapse={onTextCollapse}
						>
							{networkConnection?.description}
						</Post.Content>
					</div>
				) : null}
				<DynamicLongTextSection
					collapsedNumberOfLines={3}
					collapsedTitle={t('Read more')}
					expandedTitle={t('Show less')}
					highlightedText={highlightedText}
					textFields={longTextFields}
					onCollapse={onTextCollapse}
				/>
			</BlockLink>
		</Post>
	);
};

// Set unique styles for DynamicFieldDataGrid depending on some static field labels.
const dynamicFieldStyle = {
	Subcat: styles.subcategoryField,
};

export interface INetworkConnectionPostProps {
	networkConnection: TNetworkConnectionWithReactions;
	/** The community ID from the current viewed network. Pass it only from `/networks/dynamic_fields/*` paths.  */
	currentNetworkId?: TCommunity['id'];
	highlightedText?: string;
	likingDisabled?: boolean;
	onLike: (networkConnection: TNetworkConnectionWithReactions, newHasMyLike: boolean) => void;
	onToggleAlerts: (data: { relationId: string; newPostAlerts: boolean }) => void;
	onClick?: () => void;
	onTextCollapse?: () => void;
	restoreScrollRoute?: string;
	/** The preview mode is used when the post should not be intractable. Cases such as: Reporting a post, etc. */
	previewMode?: boolean;
	meatballMenuOptions?: TMeatballMenuOption[];
}

export default NetworkConnectionPost;
