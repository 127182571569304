import { Page } from '../../components';
import { useMe, useTranslation, useNotification, useDebouncedCallback, useService } from '@hooks';
import { useUpdateUserMutation } from '@ui-modules/profile/hooks/useUpdateUserMutation';
import { useInitAlgoliaSearchMutation } from '@ui-modules/globalSearch/hooks/useInitAlgoliaSearchMutation';
import styles from './DealsSettingsPage.module.css';
import { SwitchButton } from '@ui-kit';
import { isUserFeatureEnabled } from '@utils';

const DealsSettingsPage = () => {
	const { t } = useTranslation();
	const { user } = useMe();
	const { showSuccess, showInfo } = useNotification();
	const analytics = useService('AnalyticsService');
	const { mutate: refetchAlgoliaSearchToken } = useInitAlgoliaSearchMutation();

	const isInvestDisabled = !isUserFeatureEnabled(user, 'invest');
	const { mutate: updateUser, isLoading } = useUpdateUserMutation(false, {
		onSuccess: (updatedUser) => {
			const enabled = updatedUser.features?.invest?.owner;
			if (enabled) {
				showSuccess({ title: t('Invest enabled') });
			} else {
				showInfo({ title: t('Invest disabled') });
			}
			refetchAlgoliaSearchToken();
		},
	});
	const onUpdateDealSettings = useDebouncedCallback((enabled: boolean) => {
		if (user.features) {
			const features = { ...user.features, invest: { ...(user.features?.invest || {}), owner: enabled } };
			updateUser({ features });
			analytics.trackEvent('InvestFeaturePreferenceChanged', { invest_feature_hidden: !enabled });
		}
	});

	return (
		<Page title={t('Invest')}>
			<div className={styles.dealsSettings}>
				<h3>{t('Invest')}</h3>
				<div className={styles.dealsSettings__card}>
					<h4>{t('Invest')}</h4>
					<div className={styles.dealsSettings__controls}>
						<span>{t('Hide Invest')}</span>
						<SwitchButton
							disabled={isLoading}
							loading={isLoading}
							value={isInvestDisabled}
							onChange={() => onUpdateDealSettings(isInvestDisabled)}
						/>
					</div>
					<span className={styles.dealsSettings__hint}>
						{t('Hide the “Invest” tab in the navigation bar and in  Networks, and disable Deal notifications.')}
					</span>
				</div>
			</div>
		</Page>
	);
};

export default DealsSettingsPage;
