import {
	useCallback,
	useMemo,
	useNavigate,
	useParams,
	useTrackAlgoliaEventEffect,
	useTranslation,
	useBlockedAndBlockingUsers,
	useCountriesRegions,
	useGetContactCardQuery,
	useService,
} from '@hooks';
import type { TPhoneContactType } from '@tiger21-llc/connect-shared/src/typings/entities/Profile.type';
import { ActivityIndicator, When } from '@ui-kit';
import { useOpenDirectChatMutation } from '@ui-modules/chat/hooks/useOpenDirectChatMutation';
import ContactActionButtonSet from '@ui-modules/contacts/components/ContactActionButtonSet';
import ContactCard from '@ui-modules/contacts/components/ContactCard';
import ContactsNote from '@ui-modules/contacts/components/ContactsNote';
import ContactsPersonalInfo from '@ui-modules/contacts/components/ContactsPersonalInfo';
import DisplayContact from '@ui-modules/contacts/components/DisplayContact';
import { useContact } from '@ui-modules/contacts/hooks/useContact';
import { makeBillingAddressContact } from '@ui-modules/contacts/utils/makeBillingAddressContact';
import {
	formatAddressToString,
	translateAddressLabelToTitle,
	translateEmailLabelToTitle,
	translatePhoneLabelToTitle,
	compact,
	getMapSearchLink,
	extractIdFromIri,
} from '@utils';
import { FullScreenPageLayout, Page } from '../../components';
import { ROUTES } from '../../routes';
import styles from './ContactPage.module.css';
import type { TEmailContact, TPhoneContact } from '@typings';
import type { TMixPanelAnalyticsEvents } from '../../../services/interfaces/AnalyticsService.MixPanel.interface';

const ContactPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mutate: openDirectChat } = useOpenDirectChatMutation();
	const analytics = useService('AnalyticsService');

	const { profileId } = useParams<IContactPageParams>();
	const { data: profile, isMe, isFetching } = useGetContactCardQuery(profileId);
	const { addContact, updateNote, removeContact } = useContact();
	const { formatCountryName, formatRegionNameByCountry } = useCountriesRegions();

	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const contactIsBlocked = useMemo(
		() => blockedAndBlockingUsers.some((blockedUser) => extractIdFromIri(blockedUser.profileId) === profileId),
		[blockedAndBlockingUsers, profileId],
	);

	const viewProfile = useCallback(
		() => (isMe ? navigate(ROUTES.profile()) : navigate(ROUTES.memberProfile(profile?.id))),
		[isMe, profile],
	);

	const visibleAddresses = useMemo(() => {
		if (!profile) return [];
		return compact([
			...(profile.address ?? []),
			profile.billingAddress && isMe ? makeBillingAddressContact(profile.billingAddress) : null,
		]);
	}, [profile, isMe]);

	useTrackAlgoliaEventEffect('User Contact Viewed', { userSlug: String(profile?.user.id) }, [!isMe, profile?.user.id]);
	const trackContactInteraction = (
		interaction_type: TMixPanelAnalyticsEvents['ContactInteractions']['interaction_type'],
	) => analytics.trackEvent('ContactInteractions', { interaction_type, contact_id: profile.user.id });

	return (
		<Page title={t('Contact')}>
			<FullScreenPageLayout
				headerTitle={t('Contact Info')}
				rightButtonText={isMe ? t('Edit') : undefined}
				onRightButtonPress={isMe ? () => navigate(ROUTES.editContact()) : undefined}
			>
				<>
					{isFetching && !profile ? <ActivityIndicator type="fit" /> : null}
					{profile ? (
						<div className={styles.contact__container}>
							<ContactsPersonalInfo
								profileTitle={profile.title}
								profileType={profile['@type']}
								user={profile.user}
								onViewProfile={viewProfile}
							/>
							{isMe ? null : (
								<ContactActionButtonSet
									callDisabled={contactIsBlocked}
									chatDisabled={contactIsBlocked}
									emailDisabled={contactIsBlocked}
									emails={profile.emails || []}
									isFetching={isFetching}
									isInAddressBook={!!profile?.contactRecord}
									phones={profile.phones || []}
									onAddToAddressBook={() => {
										addContact({
											userId: profile.user.id,
											profileId: profile.id,
										});
										trackContactInteraction('Add');
									}}
									onCall={(value, label) => {
										trackContactInteraction(`Call - ${String(label)}`);
									}}
									onEmail={(value, label) => {
										trackContactInteraction(`Email - ${String(label)}`);
									}}
									onOpenChat={() => {
										openDirectChat(profile.user.slug);
										trackContactInteraction('Chat');
									}}
									onRemoveContact={() => {
										removeContact({
											contactId: profile?.contactRecord?.id as string,
											userId: profile.user.id,
											profileId: profile.id,
										});
										trackContactInteraction('Remove');
									}}
								/>
							)}
							<When condition={!contactIsBlocked}>
								{profile.phones.length ? (
									<ContactCard title={t('Phone')}>
										{profile.phones.map((phoneNumber: TPhoneContact) => (
											<DisplayContact
												key={phoneNumber.type + phoneNumber.tel}
												label={translatePhoneLabelToTitle(phoneNumber.type as TPhoneContactType, t)}
												link={`tel:${phoneNumber.tel}`}
												trackingName="contacts--phone-call"
												value={phoneNumber.tel}
												onClick={() => {
													trackContactInteraction(`Call - ${phoneNumber.type}`);
												}}
											/>
										))}
									</ContactCard>
								) : null}
								{profile.emails.length ? (
									<ContactCard title={t('Email')}>
										{profile.emails.map((email: TEmailContact) => (
											<DisplayContact
												key={email.email + email.type}
												label={translateEmailLabelToTitle(email.type, t)}
												link={`mailto:${email.email}`}
												trackingName="contacts--send-email"
												value={email.email}
												onClick={() => {
													trackContactInteraction(`Email - ${email.type}`);
												}}
											/>
										))}
									</ContactCard>
								) : null}
								{visibleAddresses.length ? (
									<ContactCard title={t('Address')}>
										{visibleAddresses.map((address) => (
											<DisplayContact
												key={address.type}
												label={translateAddressLabelToTitle(address.type, t)}
												link={getMapSearchLink(address.address)}
												linkBlank
												value={formatAddressToString({
													...address.address,
													// Values can be as ISO format (US) as formal value (United States)
													country: formatCountryName(address.address.country) as string,
													state: formatRegionNameByCountry(address.address.country, address.address.state) as string,
												})}
												onClick={() => {
													trackContactInteraction(`Address - ${address.type}`);
												}}
											/>
										))}
									</ContactCard>
								) : null}
							</When>
							{profile?.contactRecord ? (
								<ContactsNote
									note={profile?.contactRecord.note || ''}
									onChangeNote={(note) =>
										updateNote({ note, contactId: profile?.contactRecord?.id as string, profileId: profile.id })
									}
								/>
							) : null}
						</div>
					) : (
						<div />
					)}
				</>
			</FullScreenPageLayout>
		</Page>
	);
};
export interface IContactPageParams extends Record<string, string | undefined> {
	profileId: string;
}

export default ContactPage;
