import { useState } from '@hooks';
import type { TAttachments } from '@typings';
import { CloseIcon, VideoAttachmentIcon } from '@ui-kit';
import { clsx } from '@utils';
import styles from './AttachmentTextArea.module.css';
import LightBox from 'react-image-lightbox';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { useImageView } from './useImageView';
import FileTypeAvatar from '../FileTypeAvatar';

const AttachmentTextArea = (
	{
		value,
		placeholder,
		disabled,
		attachments,
		attachmentsError,
		attachmentEditingDisabled,
		onChange,
		onRemoveAttachment,
		resize,
		delayedResize,
		onPaste,
		...props
	}: IAttachmentTextAreaProps,
	ref: ForwardedRef<HTMLTextAreaElement>,
) => {
	const [isFocused, setIsFocused] = useState(false);
	const imagesUrls = attachments.filter((attachment) => attachment.fileType === 'image').map((el) => el.url || '');
	const { currentImage, nextImage, prevImage, handleClick, handleClose, handleMovePrev, handleMoveNext } =
		useImageView(imagesUrls);

	return (
		<>
			<div className={styles.textarea__container} data-focused={isFocused}>
				{attachments?.length ? (
					<>
						{attachmentsError ? <span className={styles.attachment__error}>{attachmentsError}</span> : null}
						<div className={styles.attachment__container}>
							{attachments.map((attachment, index) => (
								<div className={styles.attachment} key={`${attachment.name}-${attachment.url}-${index}`}>
									{attachmentEditingDisabled ? null : (
										<button className={styles.attachment__button} onClick={() => onRemoveAttachment(attachment)}>
											<CloseIcon height={8} width={8} />
										</button>
									)}
									{attachment.fileType === 'image' ? (
										<button
											className={styles.attachment__buttonImg}
											onClick={() => {
												const imageIndex = imagesUrls.findIndex((img) => img === attachment?.url);
												handleClick(imageIndex);
											}}
										>
											<img alt={attachment.name} className={styles.attachment} src={attachment.url} />
										</button>
									) : attachment.fileType === 'file' ? (
										<div className={styles.attachment__file}>
											<FileTypeAvatar mimeType={attachment?.mimeType || ''} />
										</div>
									) : (
										<div>
											<video controls height="60" width="60">
												<source src={attachment.url} />
												<track default kind="captions" src={attachment.url} srcLang="en" />
											</video>
											<VideoAttachmentIcon className={styles.attachment__videoIcon} height={16} width={16} />
										</div>
									)}
								</div>
							))}
						</div>
					</>
				) : null}
				<textarea
					className={clsx(styles.textarea)}
					disabled={disabled}
					placeholder={placeholder}
					ref={ref}
					rows={1}
					value={value}
					onBlur={() => setIsFocused(false)}
					onChange={(e) => {
						resize();
						onChange && onChange(e);
					}}
					onCut={delayedResize}
					onDrop={delayedResize}
					onFocus={() => setIsFocused(true)}
					onKeyDown={delayedResize}
					onPaste={(event) => {
						onPaste?.(event);
						delayedResize()
					}}
					{...props}
				/>
			</div>
			{!!currentImage && (
				<LightBox
					enableZoom={false}
					mainSrc={currentImage}
					mainSrcThumbnail={currentImage}
					nextSrc={nextImage}
					nextSrcThumbnail={nextImage}
					prevSrc={prevImage}
					prevSrcThumbnail={prevImage}
					onCloseRequest={handleClose}
					onMoveNextRequest={handleMoveNext}
					onMovePrevRequest={handleMovePrev}
				/>
			)}
		</>
	);
};

export interface IAttachmentTextAreaProps
	extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
	value?: string;
	placeholder?: string;
	disabled?: boolean;
	attachmentEditingDisabled?: boolean;
	attachmentsError?: string;
	attachments: TAttachments[];
	onRemoveAttachment: (file: TAttachments) => void;
	delayedResize: () => void;
	resize: () => void;
}

export default forwardRef(AttachmentTextArea);
