import { ActivityIndicator } from '@ui-kit';
import { useTranslation, useSearchParams } from '@hooks';
import { useGlobalSearchResults } from '@ui-modules/globalSearch/hooks/useGlobalSearchResults';
import ExpandableResultsBlock from '@ui-modules/globalSearch/components/ExpandableResultsBlock/ExpandableResultsBlock';
import SearchNoMatch from '@ui-modules/globalSearch/components/SearchNoMatch';
import type { GLOBAL_SEARCH_DEFINITION } from '@constants';
import styles from './SearchResultsPage.module.css';

const SearchResultsPage = () => {
	const { t } = useTranslation();
	const searchQueryParam = useSearchParams()[0].get('searchQuery') || '';

	const { data: searchResults, isLoading } = useGlobalSearchResults(searchQueryParam);
	const valuesFromSearchResults = searchResults && Object.values(searchResults);
	// Members, Networks, etc.
	const searchDefinitions =
		searchResults && (Object.keys(searchResults) as Array<keyof typeof GLOBAL_SEARCH_DEFINITION>);

	return !isLoading ? (
		<>
			{valuesFromSearchResults?.flat().length ? (
				<div className={styles.searchResults__wrapper}>
					<div className={styles.searchResults__header}>
						<span className={styles.searchResults__title}>{t('Search results for')}</span>
						<span className={styles.searchResults__query}>{`'${searchQueryParam}'`}</span>
					</div>
					{searchDefinitions &&
						Object.values(searchResults).map((resultsGroup, index) => {
							return resultsGroup.length ? (
								<div className={styles.searchResults__categoryWrapper} key={index}>
									<ExpandableResultsBlock
										definition={searchDefinitions[index]}
										results={resultsGroup}
										searchQuery={searchQueryParam}
									/>
								</div>
							) : null;
						})}
				</div>
			) : (
				<div>
					<SearchNoMatch searchQuery={searchQueryParam} />
				</div>
			)}
		</>
	) : (
		<ActivityIndicator size="large" type="fit" />
	);
};

export default SearchResultsPage;
