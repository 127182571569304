import { NotificationIcon, NotificationOffIcon, Button } from '@ui-kit';
import { useTranslation } from '@hooks';
import { clsx, extractIdFromIri } from '@utils';
import styles from './DealAlertsButton.module.css';
import type { KeyboardEvent, MouseEvent } from 'react';
import type { TDealMemberRelation } from '@typings';

const DealAlertsButton = ({ dealMemberRelation, onToggle, isButtonMode, textButtonStyle, iconSize = 12, color = "#666666" }: IDealAlertsButtonProps) => {
	const { t } = useTranslation();

	if (!dealMemberRelation) return null;
	const { status, newPostAlerts, '@id': iri } = dealMemberRelation;
	const isHidden = !status;
	const isDisabled = status === 'not interested';
	const isOn = isDisabled ? false : newPostAlerts;
	const title = isOn ? t('Alerts on') : t('No alerts');

	const toggle = (event: MouseEvent<HTMLDivElement> & KeyboardEvent<HTMLDivElement>) => {
		event.stopPropagation();
		if (isDisabled) return;
		onToggle({ newPostAlerts: !newPostAlerts, relationId: extractIdFromIri(iri) });
	};

	if (isHidden) return null;
	if (isButtonMode)
		return (
			<Button
				disabled={isDisabled}
				icon={
					isOn ? (
						<NotificationIcon fill="#333333" height={12} width={15} />
					) : (
						<NotificationOffIcon fill="#333333" height={16} width={15} />
					)
				}
				iconPosition="left"
				title={title}
				type="outline"
				variant="small"
				onClick={() => {
					onToggle({ newPostAlerts: !newPostAlerts, relationId: extractIdFromIri(iri) });
				}}
			/>
		);
	return (
		<div
			className={clsx(styles.dealAlertsButton__button, isDisabled && styles.dealAlertsButton__button_disabled, textButtonStyle)}
			role="button"
			tabIndex={0}
			onClick={toggle}
			onKeyDown={toggle}
		>
			{isOn ? (
				<NotificationIcon className={styles.dealAlertsButton__notifierIcon} fill={color} height={iconSize} width={iconSize} />
			) : (
				<NotificationOffIcon className={styles.dealAlertsButton__notifierIcon} fill={color} height={iconSize} width={iconSize} />
			)}
			<span className={styles.dealAlertsButton__notifier}>{title}</span>
		</div>
	);
};

export interface IDealAlertsButtonProps {
	dealMemberRelation: TDealMemberRelation | undefined;
	isButtonMode?: boolean;
	onToggle: (data: { relationId: string; newPostAlerts: boolean }) => void;
	textButtonStyle?: string;
	iconSize?: number;
	color?: string;
}

export default DealAlertsButton;
