import { useMessageContext, messageHasReactions, messageHasAttachments, MessageTimestamp } from 'stream-chat-react';
import styles from './OtherMessage.module.css';
import { clsx } from '@utils';
import { useTranslation } from '@hooks';
import MessageAvatar from '@ui-modules/chat/components/MessageAvatar';
import MessageInner from '@ui-modules/chat/components/MessageInner';
import type { MessageResponse } from 'stream-chat';
import { formatChatUserName } from '@ui-modules/chat/utils/formatChatUserName';

const OtherMessage = ({ quotedMessage, flagMessageIds, chatLogicIsBlocked, isOneToOneChat }: IMessageParams) => {
	const { endOfGroup, firstOfGroup, groupedByUser, highlighted, isMyMessage, isReactionEnabled, message } =
		useMessageContext();

	const hasAttachment = messageHasAttachments(message);
	const hasReactions = messageHasReactions(message);
	const { t } = useTranslation();

	const rootClassName = clsx(
		'str-chat__message str-chat__message-simple',
		`str-chat__message--${message.type}`,
		`str-chat__message--${message.status}`,
		isMyMessage() ? 'str-chat__message--me str-chat__message-simple--me' : 'str-chat__message--other',
		message.text ? 'str-chat__message--has-text' : 'has-no-text',
		{
			'pinned-message': message.pinned,
			'str-chat__message--has-attachment': hasAttachment,
			'str-chat__message--highlighted': highlighted,
			'str-chat__message--with-reactions str-chat__message-with-thread-link': hasReactions && isReactionEnabled,
			'str-chat__message-send-can-be-retried': message?.status === 'failed' && message?.errorStatusCode !== 403,
			'str-chat__virtual-message__wrapper--end': endOfGroup,
			'str-chat__virtual-message__wrapper--first': firstOfGroup,
			'str-chat__virtual-message__wrapper--group': groupedByUser,
		},
	);

	if (!message.type) return null; // prevent rendering 'customType: "message.date"' messages when render one message inside ReportContentPage
	if (message.type === 'deleted') {
		return (
			<div className={styles.otherMessage__deleteMessageAvatarContainer}>
				<div className={styles.otherMessage__deleteMessageAvatar}>
					<MessageAvatar />
				</div>
				<div className="str-chat__message-bubble" key={message.id}>
					<div className={styles.otherMessage__deleteMessageContainer}>
						<div className={styles.otherMessage__textMessage}>{t('This message was deleted...')}</div>{' '}
					</div>
				</div>
			</div>
		);
	}
	return (
		<div className={rootClassName} key={message.id}>
			<div className="str-chat__message-sender-avatar">
				<MessageAvatar />
			</div>
			{flagMessageIds && message.id in flagMessageIds ? (
				<div className={styles.otherMessage__reportMessageContainer}>
					<span className={styles.otherMessage__reportMessage}>{t('Message reported')}</span>
				</div>
			) : (
				<MessageInner chatLogicIsBlocked={chatLogicIsBlocked} quotedMessage={quotedMessage} />
			)}

			<div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
				{!isMyMessage() && !isOneToOneChat && !!message.user && (
					<span className="str-chat__message-simple-name">{formatChatUserName(t)(message)}</span>
				)}
				<MessageTimestamp calendar customClass="str-chat__message-simple-timestamp" />
			</div>
		</div>
	);
};

export default OtherMessage;
export interface IMessageParams {
	quotedMessage: MessageResponse | undefined;
	flagMessageIds: Record<string, boolean> | null | undefined;
	chatLogicIsBlocked: boolean | null;
	isOneToOneChat: boolean;
}
