import styles from './AvatarGroup.module.css';
import type { Channel } from 'stream-chat';
import { Avatar } from '@ui-kit';
import { useQuery } from '@hooks';
import { useService } from '@services';
import { dayjs } from '@utils';

const AvatarGroup = ({ channel }: IAvatarGroupProps) => {
	const chat = useService('ChatService');
	const reactQuery = useService('ReactQueryService');

	const { data: avatars = [] } = useQuery(
		reactQuery.queryKeys.getChatMemberAvatar(String(channel.id)),
		async () => await chat.getMemberAvatar(channel),
		{
			staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
		},
	);

	const renderFourImages = () => {
		return (
			<div className={styles.avatar__container}>
				<div>
					<div className={styles.avatar__fourImagesContainer}>
						<img alt="" className={styles.avatar__fourImages} src={avatars[0]}></img>
					</div>
					<div className={styles.avatar__fourImagesContainer}>
						<img alt="" className={styles.avatar__fourImages} src={avatars[1]}></img>
					</div>
				</div>
				<div>
					<div className={styles.avatar__fourImagesContainer}>
						<img alt="" className={styles.avatar__fourImages} src={avatars[2]}></img>
					</div>
					<div className={styles.avatar__fourImagesContainer}>
						<img alt="" className={styles.avatar__fourImages} src={avatars[3]}></img>
					</div>
				</div>
			</div>
		);
	};

	const renderTwoImages = () => {
		return (
			<div className={styles.avatar__container}>
				<div>
					<div className={styles.avatar__twoImagesContainer}>
						<img alt="" className={styles.avatar__twoImages} src={avatars[0]}></img>
					</div>
				</div>
				<div>
					<div className={styles.avatar__twoImagesContainer}>
						<img alt="" className={styles.avatar__twoImages} src={avatars[1]}></img>
					</div>
				</div>
			</div>
		);
	};

	const renderThreeImages = () => {
		return (
			<div className={styles.avatar__container}>
				<div>
					<div className={styles.avatar__twoImagesContainer}>
						<img alt="" className={styles.avatar__twoImages} src={avatars[0]}></img>
					</div>
				</div>
				<div>
					<div className={styles.avatar__fourImagesContainer}>
						<img alt="" className={styles.avatar__twoImages} src={avatars[1]}></img>
					</div>
					<div className={styles.avatar__fourImagesContainer}>
						<img alt="" className={styles.avatar__fourImages} src={avatars[2]}></img>
					</div>
				</div>
			</div>
		);
	};

	// This need to confirm from management what should be done when here
	const renderDefaultAvatar = () => {
		return <Avatar imageUri={null} outline={false} size={40} title={channel.data?.name} />;
	};
	const renderAvatars = () => {
		return (
			<>
				{avatars && avatars.length >= 4 && renderFourImages()}
				{avatars && avatars.length === 3 && renderThreeImages()}
				{avatars && avatars.length === 2 && renderTwoImages()}
				{(avatars.length === 0 || avatars.length === 1) && renderDefaultAvatar()}
			</>
		);
	};
	return <div>{renderAvatars()}</div>;
};

export default AvatarGroup;

export interface IAvatarGroupProps {
	channel: Channel;
}
