import { useService, useQuery, useMe, useNotification } from '@hooks';
import type { TProfile } from '@typings';

export const useGetContactCardQuery = (profileId: TProfile['id'] | undefined) => {
	const api = useService('ApiService');
	const { profile: myProfile, user: myUser } = useMe();
	const { showUnknownError } = useNotification();

	const isMe = !profileId || myProfile?.id === profileId;

	const query = useQuery(
		['profiles.contact_card', profileId],
		async () => await api.profile.getProfileContactCard(profileId as TProfile['id']),
		{
			enabled: !!profileId && !isMe,
			onError: (error: Error) => {
				showUnknownError(error);
			},
		},
	);

	//TODO FIXME [@hrant]
	const data: any = isMe ? { ...myProfile, user: myUser } : query.data;

	return { ...query, data, isMe };
};
