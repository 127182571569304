import { dealStatusTabs as tabs, ROUTES } from '@constants';
import { useTranslation, useNavigate, useMe, useMemo, useNotification } from '@hooks';

import styles from './AllDealFiltersPage.module.css';

import { FormPageLayout, Page } from '../../components';
import { ActivityIndicator, Formik, Navigate } from '@ui-kit';
import type { TEditDealFilters } from '@schemas';
import { getEditDealFiltersSchema } from '@schemas';

import useAllDealFilters from '@ui-modules/deals/hooks/useAllDealFiltersQuery';

import { useState } from 'reinspect';

import EditDealFiltersForm from '@ui-modules/deals/components/EditDealFiltersForm';
import useDealFiltersApi from '@ui-modules/deals/hooks/useDealFiltersApiQuery';
import { noop } from '@utils';
import type { ICheckmarkState } from '@ui-kit/components/CheckboxNestedInput/CheckboxNestedInput';
import { useDealsEnabled } from '@ui-modules/deals/hooks/useDealsEnabled';
import { useDealsDisabledEffect } from '@ui-modules/deals/hooks/useDealsDisabledEffect';

export function compareArrays(array1: string[], array2: string[]) {
	if (array1.length === 0 && array2.length === 0) {
		return 0;
	} else if (array1.length === 0 || array2.length === 0) {
		return 1;
	} else {
		return 1;
	}
}

const AllDealFiltersPage = () => {
	const { t } = useTranslation();
	const { user } = useMe();
	const dealStatusTabs = tabs(t);
	const { showSuccess, showError } = useNotification();
	const navigate = useNavigate();
	const [availableDealTypes, setAvailableDealTypes] = useState<ICheckmarkState[] | null>(null);
	const { data: allDealFilters, isLoading, isFetched } = useAllDealFilters();
	useDealsDisabledEffect();
	const { enabled } = useDealsEnabled();

	const filter = allDealFilters?.find((item) => item?.ownerIri === user?.['@id']);

	const statusIndex = dealStatusTabs?.findIndex((item) => item.value === String(filter?.status));
	const { patchDealFilters: applyFilters } = useDealFiltersApi();

	const initialValues: TEditDealFilters = useMemo(() => {
		return {
			status: statusIndex < 0 ? 0 : statusIndex,
			dealTypes: filter?.dealTypes || [],
			dealSubClasses: filter?.dealSubClasses || [],
			minInvestment: String(filter?.minInvestment || 0),
		};
	}, [filter?.dealSubClasses, filter?.dealTypes, filter?.minInvestment, statusIndex]);
	const initialOptionIndex = compareArrays(filter?.dealTypes || [], filter?.dealSubClasses || []);

	const [optionIndex, setOptionIndex] = useState<number>(initialOptionIndex);

	const onSubmit = async (values: TEditDealFilters) => {
		if (optionIndex === 1 && !values.dealSubClasses?.length) {
			showError({ title: t('Please select at least one asset-class') });
			return;
		}
		if (
			optionIndex === 1 &&
			!!values.dealSubClasses?.length &&
			!values.dealTypes?.length &&
			!!availableDealTypes?.length
		) {
			showError({ title: t('Please select at least one deal-type') });
			return;
		}

		await applyFilters({
			owner: user['@id'],
			dealSubClasses: values.dealSubClasses,
			minInvestment: values.minInvestment ? Number(values.minInvestment) : 0,
			status: dealStatusTabs[values?.status as number].value,
			dealTypes: values.dealTypes,
		});
		showSuccess({
			title: t('Filter updated'),
		});
		navigate(-1);
	};

	if (!enabled) return <Navigate replace={true} to={ROUTES.home()} />;

	if (isLoading && !isFetched) return <ActivityIndicator />;

	return (
		<Page title={t('All Deal Filters')}>
			{filter && (
				<Formik<TEditDealFilters>
					initialValues={getEditDealFiltersSchema().validateSync(initialValues)}
					validationSchema={getEditDealFiltersSchema()}
					onSubmit={onSubmit}
				>
					{(formProps) => (
						<FormPageLayout
							allowedNextPaths={[ROUTES.dealFilters(''), ROUTES.allDealFilters()]}
							buttonBordered={false}
							customHeaderStyles={styles.customHeader}
							customLeftIcon={<>{t('Cancel')}</>}
							formProps={formProps}
							headerTitle={t('Filter Deals')}
							saveButtonTitle={t('Apply')}
							onSuccessSubmit={noop}
						>
							<EditDealFiltersForm
								activeStatus={dealStatusTabs[statusIndex < 0 ? 0 : statusIndex].id}
								setAvailableDealTypes={setAvailableDealTypes}
								setOptionIndex={setOptionIndex}
								formProps={formProps}
								// Setting initial values based on the length of provided arrays [@hrant]
								optionIndex={optionIndex}
							/>
						</FormPageLayout>
					)}
				</Formik>
			)}
		</Page>
	);
};

export interface IAllDealFiltersPageParams extends Record<string, string | undefined> {
	id: string;
}

export default AllDealFiltersPage;
