import { useTranslation, useMemo, useMe, useSubmitOrScrollToError, useCurrentLocation } from '@hooks';
import { ActivityIndicator, Formik } from '@ui-kit';
import { FormPageLayout } from '../../../../app/components';
import { getValidateHandler } from './ConnectionForm.schema';
import { getConnectionFormSchema } from './ConnectionForm.schema';
import UserSection from './sections/UserSection';
import ContactSection from './sections/ContactSection';
import CategorySection from './sections/CategorySection';
import ConnectionDetails from './sections/ConnectionDetails';
import { AttachmentsSection } from './sections/AttachmentsSection/AttachmentsSection';
import {
	convertDynamicFields,
	convertToNestedCategories,
	useGetNetworkConnectionTemplateQuery,
} from '@ui-modules/connections/hooks/useGetNetworkConnectionTemplateQuery';
import { useCommunityQuery } from '@ui-modules/communities/hooks/useCommunityQuery';
import styles from './ConnectionForm.module.css';
import { ROUTES } from '@constants';
import type { TCommunity, TNetworkConnection, TNetworkConnectionTemplate } from '@typings';
import type { TNetworkConnectionFormValues } from './ConnectionForm.schema';
import ConnectionDynamicSection from './sections/ConnectionDynamicSection';
import { useSubmitNetworkConnection } from './useSubmitNetworkConnection';
import VersionSection from './sections/VersionSection';
import { getDynamicFieldsByVersion } from '@ui-modules/connections/utils/filterTemplateByVersion';

const ConnectionForm = ({ communityId, editValues, networkConnectionId }: IConnectionFormProps) => {
	const { t } = useTranslation();
	const { data: network } = useCommunityQuery(communityId);
	const { user, fullName } = useMe();
	const networkConnectionName = network?.networkConnectionsName as string;
	const { currentLocation } = useCurrentLocation();

	const submitOrScrollToErrorField = useSubmitOrScrollToError();

	const canPostBehalfOf = user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_CHAIR'); // TODO GET INFO WHO CAN CREATE behalf of.

	const { data: formTemplate, isLoading: isTemplateLoading } = useGetNetworkConnectionTemplateQuery(communityId);
	const onSubmit = useSubmitNetworkConnection(networkConnectionId, networkConnectionName, formTemplate, communityId);

	const validationSchema = getConnectionFormSchema(t, networkConnectionName, formTemplate);

	const initialValues = useMemo(() => {
		let initialDynamicValues = {};
		let selectedVersion = undefined;
		if ((formTemplate?.availableTemplateVersions?.length || 0) < 2) {
			formTemplate?.dynamicFields.forEach((field) => {
				if (field.type === 'heading') return;
				const value = field.type === 'boolean' && field.required ? 'false' : '';
				initialDynamicValues = {
					...initialDynamicValues,
					[field.name]: { ...field, value },
				};
			});
			selectedVersion = formTemplate?.availableTemplateVersions?.[0]?.name;
		}

		const initialCategories = formTemplate?.categories?.map((el) => ({ ...el, value: false })) || [];

		return {
			...validationSchema.getDefault(),
			currency: formTemplate?.currency,
			community: network?.['@id'],
			creator: canPostBehalfOf ? undefined : { iriId: user['@id'], name: fullName },
			categories: convertToNestedCategories(initialCategories),
			dynamicFields: initialDynamicValues,
			contactPhone: currentLocation.callingCode,
			selectedTemplateVersion: selectedVersion,
		};
	}, [formTemplate, network, currentLocation]);

	if (!communityId) return null;

	if (isTemplateLoading) return <ActivityIndicator size="medium" type="fit" />;

	return (
		<Formik
			enableReinitialize={true}
			initialValues={editValues ?? (initialValues as TNetworkConnectionFormValues)}
			validate={getValidateHandler(t, networkConnectionName, formTemplate)}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={onSubmit}
		>
			{(formProps) => {
				const filteredFieldsByVersion = getDynamicFieldsByVersion(
					formTemplate?.dynamicFields,
					formTemplate?.availableTemplateVersions,
					formProps.values.selectedTemplateVersion,
				);
				const { dynamicSections, mainSectionDynamicFields } = convertDynamicFields(filteredFieldsByVersion);

				return (
					<FormPageLayout
						allowedNextPaths={[ROUTES.createNetworkConnection(), ROUTES.editNetworkConnection()]}
						buttonBordered={false}
						customDisabled={!formProps.dirty}
						customLeftIcon={<span className={styles.backLink}>{t('Cancel')}</span>}
						formProps={formProps}
						headerTitle={
							editValues
								? t('Edit {{networkConnectionName}}', { networkConnectionName })
								: t('Create {{networkConnectionName}}', { networkConnectionName })
						}
						saveButtonTitle={editValues ? t('Save') : t('Post')}
						scrollEnabled
						onSaveHandler={() =>
							formProps.validateForm().then(() => {
								submitOrScrollToErrorField(formProps.submitForm);
							})
						}
					>
						<div className={styles.content}>
							<p className={styles.optionalHint}>{t('All fields required unless marked “optional”')}</p>
							<VersionSection
								formProps={formProps}
								formTemplate={formTemplate as TNetworkConnectionTemplate}
								isEdit={!!editValues}
							/>
							<UserSection
								communityId={communityId}
								formProps={formProps}
								isEdit={!!editValues}
								isUserSearchShown={canPostBehalfOf}
								networkConnectionName={networkConnectionName}
							/>
							<CategorySection communityId={communityId} formProps={formProps} />
							<ContactSection formProps={formProps} networkConnectionName={networkConnectionName} />
							<ConnectionDetails
								donationTitle={formTemplate?.actionTemplate?.buttonLinkFormLabel}
								dynamicFields={mainSectionDynamicFields}
								formProps={formProps}
								hideCurrency={formTemplate?.currency === null}
								isEdit={!!editValues}
								networkConnectionName={networkConnectionName}
								showDonationLink={formTemplate?.actionTemplate !== null}
							/>
							{dynamicSections?.map((section) => {
								return (
									<ConnectionDynamicSection
										fields={section.fields}
										formProps={formProps}
										isEdit={!!editValues}
										key={section.name}
										title={section.label}
									/>
								);
							})}
							<AttachmentsSection formProps={formProps} networkConnectionId={networkConnectionId} />
						</div>
					</FormPageLayout>
				);
			}}
		</Formik>
	);
};

interface IConnectionFormProps {
	communityId: TCommunity['id'];
	editValues?: TNetworkConnectionFormValues;
	networkConnectionId?: TNetworkConnection['id'];
	networkConnectionTitle?: TNetworkConnection['title'];
}

export default ConnectionForm;
