import type { RefObject } from 'react';
import { useRef } from 'react';
import type { INotificationFeedActivity } from '@typings';
import { useClickAway } from 'react-use';
import NotificationCard from '../NotificationCard';

const NotificationToast = ({ notification, onClick, onClose }: INotificationToastProps) => {
	const ref = useRef() as RefObject<HTMLDivElement>;
	useClickAway(ref, () => onClose?.());

	return (
		<div ref={ref}>
			<NotificationCard
				isDeal={false}
				isNetworkConnectionReply={false}
				isReply={false}
				notification={notification}
				onClick={onClick}
				onClose={onClose}
			/>
		</div>
	);
};

interface INotificationToastProps {
	notification: INotificationFeedActivity;
	onClick: () => void;
	onClose?: () => void;
}

export default NotificationToast;
