import { useMe, useMutation, useService } from '@hooks';
import { useFeedContext } from 'react-activity-feed';
import type { TActivity, TComment } from '@typings';
import type { Reaction } from 'getstream';

/** Executes toggle of your like on the certain activity.
 *  The hook should be placed within FeedContext!
 */
export const useToggleCommentLikeMutation = (activity: TActivity) => {
	const { user } = useMe();
	const algoliaAnalytics = useService('AlgoliaAnalyticsService');
	const { onToggleChildReaction } = useFeedContext();
	const { queryClient, queryKeys } = useService('ReactQueryService');

	return useMutation(async (comment: TComment) => onToggleChildReaction('like', comment as any as Reaction), {
		onSuccess(data, comment) {
			queryClient.refetchQueries(queryKeys.getPostActivityReactions(activity.id));

			algoliaAnalytics.trackEvent('User Interacted', {
				replyOwnerId: user.id,
				discussionOwnerId: activity.actor.data.userId,
			});
			algoliaAnalytics.trackEvent('Reply Liked', { discussionId: comment.id });
		},
	});
};
