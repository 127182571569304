import { ROUTES } from '@constants';
import { useEffect, useNavigate, useService, useState, useTranslation } from '@hooks';
import type { TDealFilter } from '@typings';
import { BellSlashIcon, EditPencilIcon, BellIcon } from '@ui-kit';
import { getDealFilterStatus } from '@ui-modules/networks/utils/getDealFilterStatus';
import { toString, uniq } from '@utils';

import styles from './DealFilters.module.css';
import useDealFiltersSettingsMutation from '@ui-modules/deals/hooks/useDealFiltersSettingsMutation';

const DealFilters = ({ filters, communityId = '', filterTitle, title }: IDealFiltersProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const analytics = useService('AnalyticsService');

	const [muted, setMuted] = useState<boolean>(filters?.alerts as boolean);
	const handleDealEdit = () => {
		if (communityId) {
			navigate(ROUTES.dealFilters(communityId));
		} else {
			navigate(ROUTES.allDealFilters());
		}
	};
	const { patchDealFiltersSettings } = useDealFiltersSettingsMutation(false);

	useEffect(() => {
		return () => {
			queryClient.invalidateQueries(queryKeys.getDealSettings());
			queryClient.refetchQueries(queryKeys.getDealSettings());
		};
	}, []);

	const MuteIcon = muted ? BellIcon : BellSlashIcon;
	const handleMuteToggle = () => {
		let arr: string[] = [];
		if (muted) {
			arr = [];
		} else {
			arr = ['email', 'push/expo'];
		}

		setMuted(!muted);
		patchDealFiltersSettings({
			id: filters.id as string,
			data: { notificationChannels: uniq(arr), alerts: !muted },
		});
		analytics.trackEvent('InvestAlertsChanged', { invest_alerts_preference: !muted });
		analytics.trackEvent('DealInteractions', {
			page_name: title,
			action_taken: !muted ? 'alerts on' : 'alerts off',
			interaction_type: 'alerts toggled',
		});
	};

	const statusOfDealFilter = getDealFilterStatus(t, filters?.status || null);

	return (
		<div className={styles.container}>
			<div className={styles.alertsWrapper}>
				<button className={styles.itemWrapper} onClick={handleMuteToggle}>
					<MuteIcon className={styles.bellIcon} fill="#59A5FF" height={20} width={25} />
					<span className={styles.description}>{t('Alerts {{muted}}', { muted: muted ? t('On') : t('Off') })}</span>
				</button>
			</div>
			<div>
				<span className={styles.filterDescription}>
					{t('{{status}} Deals in ', {
						status: statusOfDealFilter,
					})}
					{toString(filterTitle)}
					{t(' with filters')}
				</span>
			</div>
			<div className={styles.editWrapper}>
				<button className={styles.itemWrapper} onClick={handleDealEdit}>
					<EditPencilIcon fill="#59A5FF" height={20} width={25} />
					<span className={styles.description}>{t('Edit Filter')}</span>
				</button>
			</div>
		</div>
	);
};

export interface IDealFiltersProps {
	communityNames?: string[];
	communityId?: string;
	filters: Partial<TDealFilter>;
	filterTitle: string | undefined;
	title: string;
}

export default DealFilters;
