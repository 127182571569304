import { GLOBAL_SEARCH, GLOBAL_SEARCH_DEFINITION } from '@constants';
import { EmptyStateMessage, Portal, RightIcon } from '@ui-kit';
import { useGlobalSearchState } from '@ui-modules/globalSearch/hooks/useGlobalSearchState';
import { useGlobalSearchHistory } from '@ui-modules/globalSearch/hooks/useGlobalSearchHistory';
import { useGlobalSearchResults } from '@ui-modules/globalSearch/hooks/useGlobalSearchResults';
import { useClickAway, useDebounce, useRef, useTranslation } from '@hooks';
import SearchResult from '../SearchResult/SearchResult';
import RecentSearches from '../RecentSearch';
import SearchNoMatch from '../SearchNoMatch';
import type { RefObject } from 'react';
import type { TSearchByIndexResponse } from '@typings';
import styles from './SearchModal.module.css';

const SearchModal = ({ searchInputRef }: ISearchModal) => {
	const { t } = useTranslation();

	const { setIsSearchActive, searchQuery, goToResultsScreen } = useGlobalSearchState();
	const [debouncedSearchQuery] = useDebounce(searchQuery, 400);
	const { recentSearches, addRecentSearch } = useGlobalSearchHistory(searchQuery, debouncedSearchQuery);
	const { data: searchResults = [], isFetched } = useGlobalSearchResults(searchQuery, debouncedSearchQuery, {
		select: (data) =>
			Object.values(data)
				.flat()
				.sort((a, b) => b.rank - a.rank) // sort by search match rank descending
				.slice(0, GLOBAL_SEARCH.limitOfSearchResults),
	});
	const isSearchResultEmpty = isFetched && !searchResults?.length;
	const temporaryNoMatch = searchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength && isSearchResultEmpty;

	const portalRef = useRef() as RefObject<HTMLDivElement>;
	useClickAway(portalRef, () => setIsSearchActive(false));

	const openAllSearchResults = () => {
		addRecentSearch(searchQuery);
		goToResultsScreen(searchQuery);
	};

	return (
		<Portal>
			<div
				className={styles.searchModal__globalSearchModal}
				ref={portalRef}
				style={getHintPopupStyle(searchInputRef.current?.getBoundingClientRect())}
			>
				{!recentSearches.length && searchQuery.length === 0 ? (
					<EmptyStateMessage text={t(`You don't have any recent searches`)} />
				) : null}
				{recentSearches.length ? <RecentSearches debouncedSearchQuery={searchQuery} /> : null}
				{isSearchResultEmpty && searchQuery.length ? <SearchNoMatch searchQuery={searchQuery} /> : null}
				{searchResults &&
				searchResults.length &&
				!temporaryNoMatch &&
				searchQuery.length >= GLOBAL_SEARCH.validSearchQueryLength ? (
					<div>
						{searchResults.map((result: TSearchByIndexResponse) => {
							switch (result.definition) {
								case GLOBAL_SEARCH_DEFINITION.member:
									return (
										<div key={result.objectID}>
											<SearchResult
												avatar={result.avatar}
												biography={result.biography}
												communities={result.communities}
												definition={result.definition}
												expertise={result.expertise}
												interests={result.interests}
												investmentExpertise={result.investmentExpertise}
												name={result.name}
												objectID={result.profileID ?? result.objectID}
												profileType={result.profileType}
												searchQuery={searchQuery}
											/>
										</div>
									);
								case GLOBAL_SEARCH_DEFINITION.network:
								case GLOBAL_SEARCH_DEFINITION.group:
								case GLOBAL_SEARCH_DEFINITION.chapter:
									return (
										<div key={result.objectID}>
											<SearchResult
												definition={result.definition}
												objectID={result.objectID}
												searchQuery={searchQuery}
												title={result.title}
											/>
										</div>
									);
								case GLOBAL_SEARCH_DEFINITION.event:
								case GLOBAL_SEARCH_DEFINITION.meeting:
									return (
										<div key={result.objectID}>
											<SearchResult
												communityName={result.communityName}
												definition={result.definition}
												objectID={result.objectID}
												searchQuery={searchQuery}
												startDate={result.startDate}
												title={result.title}
											/>
										</div>
									);
								case GLOBAL_SEARCH_DEFINITION.discussion:
									return (
										<div key={result.objectID}>
											<SearchResult
												communityName={result.communityName}
												communitySlug={result.communitySlug}
												definition={result.definition}
												objectID={result.objectID}
												searchQuery={searchQuery}
												startDate={result.startDate}
												streamActivityId={result.streamActivityId}
												streamFeedId={result.streamFeedId}
												title={result.title}
											/>
										</div>
									);
								case GLOBAL_SEARCH_DEFINITION.learn:
									return (
										<div key={result.objectID}>
											<SearchResult
												definition={result.definition}
												objectID={result.objectID}
												searchQuery={searchQuery}
												startDate={result.startDate}
												streamActivityId={result.streamActivityId}
												streamFeedId={result.streamFeedId}
												title={result.title}
											/>
										</div>
									);
								case GLOBAL_SEARCH_DEFINITION.deal:
									return (
										<div key={result.objectID}>
											<SearchResult
												assetClass={result.assetClass}
												avatar={result.avatar}
												definition="deal"
												objectID={result.objectID}
												searchQuery={searchQuery}
												startDate={result.startDate}
												title={result.title}
											/>
										</div>
									);
								case GLOBAL_SEARCH_DEFINITION.networkConnection:
									return (
										<div key={result.objectID}>
											<SearchResult
												avatar={result.avatar}
												communityName={result.communityName}
												definition="networkConnection"
												objectID={result.objectID}
												searchQuery={searchQuery}
												startDate={result.startDate}
												title={result.title}
											/>
										</div>
									);
								case GLOBAL_SEARCH_DEFINITION.document:
									return (
										<div key={result.objectID}>
											<SearchResult
												definition={GLOBAL_SEARCH_DEFINITION.document}
												fileOrFolder={result.fileOrFolder}
												folderID={result.folderID}
												mimeType={result.mimeType}
												name={result.title}
												objectID={result.objectID}
												relatedEntityDefinition={result.relatedEntityDefinition}
												relatedEntityId={result.relatedEntityId}
												relatedEntityName={result.relatedEntityName}
												searchQuery={searchQuery}
												startDate={result.startDate}
											/>
										</div>
									);
							}
						})}
						<div className={styles.seeResultsWrapper} onClick={openAllSearchResults} onKeyPress={openAllSearchResults}>
							<div className={styles.searchModal__seeAllResultsWrapper}>
								<span className={styles.searchModal__seeAllResultsText}>{`See all results for '${searchQuery}'`}</span>
								<RightIcon className={styles.searchModal__seeAllResultsArrow} />
							</div>
						</div>
					</div>
				) : null}
			</div>
		</Portal>
	);
};
export default SearchModal;

function getHintPopupStyle(searchBarRect: DOMRect | undefined) {
	if (!searchBarRect) return {};

	const PADDING = 16;
	const MINIMUM_PROPER_SEARCH_BAR_WIDTH = 300;

	const widthFromSearchBarToRightScreenEdge = document.body.clientWidth - searchBarRect.left - PADDING;
	const searchBarWidthWithPaddings = searchBarRect.width + PADDING * 2;
	const maxWidth =
		searchBarRect.width < MINIMUM_PROPER_SEARCH_BAR_WIDTH
			? widthFromSearchBarToRightScreenEdge
			: searchBarWidthWithPaddings;

	const heightBetweenSearchBarAndBottomScreenEdge =
		document.body.clientHeight - searchBarRect.top - searchBarRect.height - PADDING;

	return {
		top: searchBarRect.y + 40,
		left: searchBarRect.x - PADDING,
		maxHeight: heightBetweenSearchBarAndBottomScreenEdge,
		maxWidth,
	};
}
interface ISearchModal {
	searchInputRef: RefObject<HTMLInputElement>;
}
