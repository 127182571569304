import {
	MAX_POST_IMAGE_HEIGHT,
	MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE,
	MAX_ITEMS_FOR_UPLOAD,
	POST_MESSAGE_CHAR_LIMIT,
} from '@constants';
import LightBox from 'react-image-lightbox';
import { useMemo, useRef, useState, useTranslation } from '@hooks';
import type { TAttachments } from '@tiger21-llc/connect-shared/src/typings';
import { AutoGrowInput, FileAttachmentIcon, InputErrorMessage } from '@ui-kit';
import { clsx, downloadFile, extractFileNameFromStreamUrl, onClipboardPaste } from '@utils';
import PostAttachment from '../PostAttachment';
import styles from './PostAttachments.module.css';
import { useImageView } from '@ui-kit/components/AttachmentTextArea/useImageView';
import { usePdfViewer } from '@ui-modules/files/components/PdfViewer';
import type { FocusEventHandler } from 'react';

const PostAttachments = ({
	editable,
	attachments,
	filesSize,
	setFieldValue,
	fieldName,
	value,
	forcedResize,
	errorMessage,
	isInitialItemsExceedingCount,
	onBlur,
	removeAttachment,
	uploadAttachments,
	onAddAttachmentsSuccess,
}: IPostAttachmentsProps) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const imagesUrls = attachments.filter((attachment) => attachment.fileType === 'image').map((el) => el.url || '');
	const { currentImage, nextImage, prevImage, handleClick, handleClose, handleMovePrev, handleMoveNext } =
		useImageView(imagesUrls);

	const mediaCount = attachments.filter((attachment) => attachment.fileType !== 'file')?.length;
	const { t } = useTranslation();
	const [isFocused, setIsFocused] = useState(false);
	const pdfViewer = usePdfViewer();
	const charactersError = useMemo(() => {
		return value.length > POST_MESSAGE_CHAR_LIMIT
			? t('{{countOfCharacters}} of {{maxCharacters}} characters max', {
					countOfCharacters: value.length,
					maxCharacters: POST_MESSAGE_CHAR_LIMIT,
			  })
			: '';
	}, [value]);

	const filesSizeError = useMemo(() => {
		return filesSize > MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE
			? t('Size should be limited up to {{ maxLimit }} /{{ limit }}MB', {
					maxLimit: MAX_FEED_UPLOAD_ATTACHMENTS_TOTAL_SIZE,
					limit: String(filesSize).slice(0, 5),
			  })
			: attachments.length > MAX_ITEMS_FOR_UPLOAD
			? t("You can't attach more then {{count}} items", { count: MAX_ITEMS_FOR_UPLOAD })
			: '';
	}, [filesSize, attachments]);

	return (
		<div>
			<div
				className={clsx(
					styles.attachments__container,
					isFocused && styles.attachments__container_focus,
					(charactersError || (isInitialItemsExceedingCount ? null : filesSizeError)) &&
						styles.attachments__container_error,
				)}
			>
				<div
					className={styles.attachments__textarea}
					onClick={(e) => {
						if (e.target !== e.currentTarget) return;
						textareaRef.current?.focus();
					}}
					onKeyDown={(e) => {
						if (e.target !== e.currentTarget) return;
						textareaRef.current?.focus();
					}}
				>
					<AutoGrowInput
						errorMessage={errorMessage}
						fieldName={fieldName}
						forcedResize={forcedResize}
						keepMarginBottom={false}
						maxRows={18}
						placeholder={t('Write a new post')}
						ref={textareaRef}
						setFieldValue={setFieldValue}
						size="medium"
						transparentMode
						value={value}
						onBlur={(event) => {
							setIsFocused(false);
							onBlur?.(event);
						}}
						onFocus={() => setIsFocused(true)}
						onPaste={(event) => onClipboardPaste(event, (files) => onAddAttachmentsSuccess?.(files))}
					/>
					{attachments?.length ? (
						<div className={clsx(styles.post__files, attachments.length > 3 && styles.post__filesSpacing)}>
							{attachments.map((file, index) => {
								return (
									<PostAttachment
										editable={!editable}
										file={file}
										key={`${file.name}-${file.url}-${index}`}
										mediaHeight={MAX_POST_IMAGE_HEIGHT / mediaCount}
										onDelete={(file: TAttachments) => removeAttachment(file)}
										onFileClick={() => {
											if (file.mediaType === 'application/pdf') pdfViewer.open(String(file.url));
											else downloadFile(String(file.url), extractFileNameFromStreamUrl(String(file.url)));
										}}
										onImageClick={() => handleClick(index)}
									/>
								);
							})}
						</div>
					) : null}
				</div>
				<div className={clsx(styles.attachment, isFocused && styles.attachments__container_focus)}>
					<button
						className={clsx(styles.attachment__button, !editable && styles.attachment__button_disabled)}
						disabled={!editable}
						onClick={uploadAttachments}
					>
						<FileAttachmentIcon className={styles.attachmentIcon} height={16} width={16} />
					</button>
				</div>
			</div>
			<InputErrorMessage text={charactersError || (isInitialItemsExceedingCount ? undefined : filesSizeError)} />
			{!!currentImage && (
				<LightBox
					enableZoom={false}
					mainSrc={currentImage}
					mainSrcThumbnail={currentImage}
					nextSrc={nextImage}
					nextSrcThumbnail={nextImage}
					prevSrc={prevImage}
					prevSrcThumbnail={prevImage}
					onCloseRequest={handleClose}
					onMoveNextRequest={handleMoveNext}
					onMovePrevRequest={handleMovePrev}
				/>
			)}
		</div>
	);
};

export interface IPostAttachmentsProps {
	editable: boolean;
	filesSize: number;
	value: string;
	fieldName: string;
	errorMessage?: string;
	setFieldValue: (field: string, value: string) => void;
	uploadAttachments?: () => void;
	attachments: TAttachments[];
	removeAttachment: (attachment: TAttachments) => void;
	onBlur?: FocusEventHandler<HTMLTextAreaElement>;
	forcedResize?: boolean;
	isInitialItemsExceedingCount?: boolean;
	onAddAttachmentsSuccess?: (files: File[]) => void
}

export default PostAttachments;
