import { useNavigate, useEffect } from '@hooks';
import { ROUTES } from '@constants';
import type { TCommunity, TChapterTabName } from '@typings';

/** Calculates current tab and handles behavior of navigation between tabs.
 *  It exists specifically for the GroupTabsPage.
 */
export const useChapterTabsHandler = (
	chapterId: TCommunity['id'] | undefined,
	tabNames: TChapterTabName[],
	currentTabName: string,
) => {
	const navigate = useNavigate();

	const currentTabIndex = currentTabName ? tabNames.findIndex((tab) => tab === currentTabName) : 0;
	const onChangeTab = (index: number) => {
		const tabName: TChapterTabName = tabNames[index] ?? tabNames[0];
		chapterId && navigate(ROUTES.chapterTab(chapterId, tabName));
	};

	useEffect(
		function redirectToDefaultTabIfItUnknown() {
			if (currentTabIndex === -1) {
				chapterId && navigate(ROUTES.chapterTab(chapterId), { replace: true });
			}
		},
		[currentTabIndex],
	);

	return { currentTabIndex, onChangeTab };
};
