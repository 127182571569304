import { toString, lookupMimeType, extractFileNameFromStreamUrl, extractFileExtensionFromUrl } from '@utils';
import type { TAttachments, TFile, TFileOwner } from '@typings';

/** Makes stub File entity from an URL to render via FileItem component.  */
export const makeFileFromUrl = (url: string): TFile => {
	const fileName = extractFileNameFromStreamUrl(url);
	const fileExtension = extractFileExtensionFromUrl(url);
	const mimeType = lookupMimeType(fileExtension);
	const file: TFile = {
		'@context': 'undefined',
		'@id': 'undefined',
		'@type': 'CommunityFile',
		mediaObject: {
			id: 'undefined',
			encrypted: false,
			contentUrl: url,
			originalName: fileName,
			mimeType: mimeType,
			'@id': 'undefined',
			'@type': 'undefined',
		},
		owner: null as any as TFileOwner,
		relatedEntity: 'undefined',
		id: 'undefined',
		name: fileName,
		createdAt: new Date().toISOString(),
		updatedAt: new Date().toISOString(),
	};

	return file;
};

/** Populates sinmple string with <b/> tags to render via dangerousSetInnerHTML React feature. */
export const convertHighlightingTextToHtml = (highlightingText: string | undefined, children: string) =>
	highlightingText ? toString(children).replaceAll(highlightingText, `<b>${highlightingText}</b>`) : children;

export const makeFileFromAttachment = (attachmentFile: TAttachments): TFile => {
	const file: TFile = {
		'@context': 'undefined',
		'@id': 'undefined',
		'@type': 'CommunityFile',
		mediaObject: {
			id: 'undefined',
			encrypted: false,
			contentUrl: String(attachmentFile.url),
			originalName: attachmentFile.name,
			mimeType: String(attachmentFile.mimeType),
			'@id': 'undefined',
			'@type': 'undefined',
		},
		owner: null as any as TFileOwner,
		relatedEntity: 'undefined',
		id: 'undefined',
		name: attachmentFile.name,
		createdAt: new Date().toISOString(),
		updatedAt: new Date().toISOString(),
	};

	return file;
};
