import Checkbox from '../Checkbox';
import type { ICheckboxProps } from '../Checkbox';
import type { IInputDescriptionProps } from '../InputDescription';
import InputDescription from '../InputDescription';
import InputErrorMessage from '../InputErrorMessage';
import styles from './CheckboxInput.module.css';
import { clsx } from '@utils';

const CheckboxInput = ({
	value = false,
	disabled = false,
	size = 'medium',
	label,
	description,
	errorMessage,
	showHorizontalRule = false,
	labelContainerStyles,
	labelStyles,
	labelPostfix,
	labelPostfixStyles,
	className,
	onChange,
	filterCheckbox = false,
	errored,
	descriptionTheme,
	onLabelClick,
}: ICheckboxInputProps) => {
	return (
		<div className={clsx(labelContainerStyles)}>
			<Checkbox
				className={className}
				disabled={disabled}
				errored={!!errorMessage || errored}
				filterCheckbox={filterCheckbox}
				showHorizontalRule={showHorizontalRule}
				size={size}
				value={value}
				onChange={onChange}
			>
				{label ? (
					<span role="presentation" onClick={onLabelClick}>
						<span
							className={clsx(
								styles.checkboxInput__labelText,
								disabled && styles.checkboxInput__labelText_disabled,
								labelStyles,
							)}
						>
							{label}
						</span>
						<span className={labelPostfixStyles}>{labelPostfix}</span>
					</span>
				) : null}
			</Checkbox>

			{description && <InputDescription text={description} theme={descriptionTheme} />}

			{errorMessage && <InputErrorMessage text={errorMessage} />}
		</div>
	);
};

export interface ICheckboxInputProps extends ICheckboxProps {
	/** Title of the checkbox displayed on the right side. */
	label?: string;
	/** Help text displayed between label and error message. */
	description?: string;
	/** If exists displays red error message given from form validation. */
	errorMessage?: string;
	/** If exists displays horizontal line in the checkbox. */
	showHorizontalRule?: boolean;
	/** Custom wrapper styles */
	labelContainerStyles?: string;
	/** Custom Label/Text styles*/
	labelStyles?: string;
	/** Text, rendered after main label text. */
	labelPostfix?: string;
	/** 'labelPostfix' text style */
	labelPostfixStyles?: string;
	/** Click handler for label. */
	onLabelClick?: () => void;
	/** Optional flag for checkboxes on Deal Filter page */
	filterCheckbox?: boolean;
	/** Additional custom style for check box */
	className?: string;
	errored?: boolean;
	descriptionTheme?: IInputDescriptionProps['theme'];
}

export default CheckboxInput;
