import { MessageList, useChannelActionContext } from 'stream-chat-react';
import { useEffect, useUserSessionStorageValue } from '@hooks';

const actions = ['react'];

const ChatMessageList = () => {
	const { jumpToFirstUnreadMessage, jumpToMessage } = useChannelActionContext();
	const { storageSavedValue: activeChatMessageId, setStorageSavedValue } =
		useUserSessionStorageValue('active-chat-message-id');

	useEffect(
		function jumpToInitialMessage() {
			if (activeChatMessageId) {
				jumpToMessage(activeChatMessageId)
					.then(() => {
						setStorageSavedValue('');
					})
					.catch(() => {
						jumpToFirstUnreadMessage(); // if message not found (because it is a different channel or message was deleted)
					});
			} else {
				jumpToFirstUnreadMessage();
			}
		},
		[jumpToFirstUnreadMessage, jumpToMessage],
	);

	return <MessageList hideDeletedMessages={false} messageActions={actions} noGroupByUser />;
};

export default ChatMessageList;
