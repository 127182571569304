import {
	useToggle,
	useTranslation,
	useEffect,
	useService,
	useMemo,
	useBlockedAndBlockingUsers,
	useLogger,
	useMe,
} from '@hooks';
import { Notifier } from '@ui-kit';
import { ActivityEntity } from '@utils';
import { FeedNotifierEmitter } from './FeedNotifierEmitter';
import styles from './FeedNotifier.module.css';
import type { NewActivitiesNotificationProps } from 'react-activity-feed';
import type { FeedAPIResponse } from 'getstream';
import type { QueryKey } from '@tanstack/react-query';
import type { IInfinitePaginatedListData, TActivity, TNetworkConnectionWithReactions, TUid } from '@typings';

const FeedNotifier = ({ adds = [], deletes = [], userId, title, onClick, activityId }: IFeedNotifierProps) => {
	const { t } = useTranslation();
	const { user } = useMe();
	const { queryClient, queryKeys } = useService('ReactQueryService');
	const logger = useLogger('FeedNotifier');
	const feedService = useService('FeedService');
	const reactQuery = useService('ReactQueryService');

	const { blockedAndBlockingUsers } = useBlockedAndBlockingUsers();
	const [isLoading, setIsLoading] = useToggle(false);

	const reallyAdds: TActivity[] = useMemo(
		/** Due to the fact that when you like the post Stream returns is as 'adds' update
		 *  and we can't recognize whether an activity is really added or just liked
		 * 	we have to try to filter out the reaction updates from the 'adds' updates
		 * 	by comparing the list of loaded activities with the list of 'adds' activities
		 * 	to avoid showing 'New Posts' popup.
		 *  Fixes T21C-4741 but partially, because it depends on activities which are already loaded to react-query cache.
		 *  @author DmitriyNikolenko
		 */
		function filterReactionChangesAndBlockedUsers() {
			try {
				const feedCache = queryClient.getQueriesData<FeedAPIResponse>(queryKeys.getUserFeed(userId)) as TFeedQueryCache;
				const loadedActivityIds = feedCache.flatMap(
					([, cacheRecord]) => cacheRecord?.results?.map((activity) => activity.id) as any,
				) as string[];
				const addsActivities = adds as TActivity[];
				const addsWithoutReactionChanges = addsActivities.filter(
					(activity) => !loadedActivityIds.includes(activity.id),
				);
				const addsWithoutReactionChangesAndBlockedUsers = addsWithoutReactionChanges.filter(
					(activity) =>
						!blockedAndBlockingUsers.some((blockedUser) => blockedUser.id === activity?.actor?.data?.userId),
				);

				return addsWithoutReactionChangesAndBlockedUsers;
			} catch (error) {
				logger.error(error);
				return adds as TActivity[];
			}
			/* 
			// TODO If backed will set `updatedAt` field for an activity updates we can use this code instead of the above one. [@DmitriyNikolenko] 
			const closestMinute = dayjs().subtract(15, 'seconds');
			return adds.filter((activity) => {
				const creationDate = dayjs(activity.updatedAt as string);
				return creationDate.isAfter(closestMinute);
			});
			*/
		},
		[adds, userId],
	);

	const isVisible = reallyAdds.length > 0;

	const refresh = () => (onClick as () => void)?.();

	useEffect(
		function updateActivityEntitiesReactions() {
			for (const activity of reallyAdds) {
				const activityId = activity.id;
				const activityEntity = new ActivityEntity(activity);

				feedService.getActivity(activityId as string, userId).then((activity) => {
					if (!activity) return;

					if (activityEntity.isNetworkConnectionActivity()) {
						const communityId = activityEntity.communityId;
						reactQuery.updateNetworkConnectionReactionsInCache(activity, communityId);
					} else if (activityEntity.isDealActivity()) {
						reactQuery.refetchDealsAfterReactionsMutation(activity);
					} else if (activityEntity.isFromNetworkDiscussion()) {
						const communitySlug = activity.collectionCommunityReference?.data.slug;
						reactQuery.queryClient.setQueriesData<IInfinitePaginatedListData<TNetworkConnectionWithReactions>>(
							reactQuery.queryKeys.getUserFeed(communitySlug),
							(cache) => {
								if (!cache) return cache;
								return reactQuery.updateReactionsInInfiniteQueryCache(cache, activity);
							},
						);
					}

					reactQuery.queryClient.setQueriesData<IInfinitePaginatedListData<TNetworkConnectionWithReactions>>(
						reactQuery.queryKeys.getUserFeed(user.id),
						(cache) => {
							if (!cache) return cache;
							return reactQuery.updateReactionsInInfiniteQueryCache(cache, activity);
						},
					);
				});
			}
		},
		[reallyAdds],
	);

	useEffect(() => {
		if (!isVisible) setIsLoading(false);
	}, [isVisible]);

	const handleClick = () => {
		setIsLoading(true);
		if (activityId) reactQuery.queryClient.removeQueries(reactQuery.queryKeys.getPostActivityReactions(activityId));
		refresh();
		queryClient.invalidateQueries(queryKeys.getPinnedBanners());
	};

	FeedNotifierEmitter.useListener(activityId, handleClick);

	if (!isVisible) return null;
	return (
		<div className={styles.feedNotifier}>
			<Notifier isLoading={isLoading} title={title ?? t('New Posts')} onClick={handleClick} />
		</div>
	);
};

type TFeedQueryCache = [QueryKey, FeedAPIResponse][];

export interface IFeedNotifierProps extends NewActivitiesNotificationProps {
	userId: string;
	title?: string;
	activityId?: TUid;
}

export default FeedNotifier;
