import { forwardRef } from 'react';
import { Avatar, Gap } from '@ui-kit';
import { clsx } from '@utils';
import styles from './AutocompleteSuggestionItem.module.css';
import type { ComponentType } from 'react';
import type { TIsoDate, TProfile, TUser } from '@typings';
import type { SuggestionCommand, SuggestionItemProps, SuggestionUser } from 'stream-chat-react';
import type { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

/** Custom wrapper around Stream Chat AutoCompleteSuggestionItem
 *  @see https://getstream.io/chat/docs/sdk/reactnative/ui-components/autocomplete-suggestion-item/
 */

/*
 * For mentions our logic works fine for other command such as / or : it crash the app (https://tiger21.atlassian.net/browse/T21C-3665)
 * Make some changes and ask the way we are using this component currently example : https://getstream.io/chat/docs/sdk/react/guides/customization/suggestion_list/#the-code-5
 */
const AutocompleteSuggestionItem = (
	{ component, item, key, onClickHandler, onSelectHandler, selected }: SuggestionItemProps,
	ref: React.Ref<HTMLDivElement>,
) => {
	const { id, name, avatar, profileType } = item as TSuggestionUser;
	if (isMention(item)) {
		return (
			<div
				className={clsx(styles.autocompleteSuggestionItem, selected && styles.autocompleteSuggestionItem__selected)}
				key={key}
				ref={ref}
				role="button"
				tabIndex={0}
				onClick={(event) => onClickHandler(event, item)}
				onKeyPress={(event) => onClickHandler(event, item)}
				onMouseEnter={() => onSelectHandler(item)}
			>
				<Avatar imageUri={avatar || undefined} outline={profileType === 'ProfileChair'} size={32} title={name} />
				<Gap gap={16} horizontal />
				<h4>{name}</h4>
			</div>
		);
	} else {
		return null;
	}
};

export interface IAutocompleteSuggestionItemProps extends SuggestionItemProps<DefaultStreamChatGenerics> {}

export default forwardRef(AutocompleteSuggestionItem as any) as ComponentType<
	SuggestionItemProps<DefaultStreamChatGenerics>
>;

// Helpers
type SuggestionItem = SuggestionUser | SuggestionCommand /* | BaseEmoji */;
const isMention = (output: SuggestionItem): output is SuggestionUser =>
	(output as SuggestionUser).id != null && (output as SuggestionUser).native == null;

type TSuggestionUser = {
	avatar: string | null;
	banned: boolean;
	created_at: TIsoDate;
	id: TUser['slug'];
	name: string;
	online: boolean;
	profileId: TProfile['id'];
	profileIri: TProfile['@id'];
	profileType: TProfile['@type'];
	role: 'user'; // TODO other possible values?
	roles: TUser['roles'];
	updated_at: TIsoDate;
	userId: TUser['id'];
	userIri: TUser['@id'];
};
