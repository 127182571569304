import { useDropzone, useNotification, useTranslation } from '@hooks';
import {
	COMMUNITY_DOCUMENTS,
	IMAGES_MIME_TYPE,
	VIDEOS_MIME_TYPE,
	COMMUNITY_DOCUMENTS_EXCLUDE_IMAGE,
	MAX_SIZE_FOR_UPLOAD,
} from '@constants';

const MAX_FILE_SIZE_LIMIT = MAX_SIZE_FOR_UPLOAD * 1024 * 1024; // 100MB => If one file (not video) is more then 100MB stream return 413 error;

export const usePickUpAttachments = (
	uploadFile: (files: File[]) => void,
	onlyMediaAccepted?: boolean,
	onlyFilesAccepted?: boolean,
	maxSize?: number,
) => {
	const { t } = useTranslation();
	const { showError } = useNotification();

	const { open, getInputProps } = useDropzone({
		multiple: true,
		onDropRejected: (err) => {
			const errorIsRelatedToSize = err
				.map((item) => item.errors.map((err) => err.code === 'file-too-large'))
				.some((item, index) => !!item?.[index]);

			if (errorIsRelatedToSize) {
				showError({
					title: t('File size is larger than {{limit}}', { limit: '100MB' }),
					subtitle: t('Please use a post in the Discussions area instead'),
				});
			} else {
				showError({ title: t('Rejected'), subtitle: t('Not allowed file type') });
			}
		},
		onDrop: (acceptedFiles: File[]) => {
			const hasEmptyFiles = acceptedFiles.some((item) => item.size <= 0);
			const hasLongNameFiles = acceptedFiles.some((item) => (item.name || '')?.length > 100);
			const fileSizeLimit = acceptedFiles.some(
				(item) => item.size > MAX_FILE_SIZE_LIMIT && !item.type.includes('video'),
			);

			if (hasEmptyFiles) {
				showError({
					title: t('The uploaded file appears to be empty.'),
					subtitle: t('Please ensure that the file you are uploading contains data.'),
				});
			}

			if (hasLongNameFiles) {
				showError({
					title: t('The file name is too long.'),
					subtitle: t('Please reduce the size of file name.'),
				});
			}
			if (fileSizeLimit) {
				showError({
					title: t('File size is larger than {{limit}}', { limit: '100MB' }),
					subtitle: t('Please use a post in the Discussions area instead'),
				});
			}
			if (acceptedFiles) {
				uploadFile(acceptedFiles.filter(getIsFileAccepted));
			}
		},
		accept: onlyFilesAccepted ? acceptedFileTypes : onlyMediaAccepted ? acceptedMediaTypes : acceptedAllFileTypes,
		maxSize,
	});

	return { openFilePicker: open, getInputProps };
};

const getIsFileAccepted = (item: File) =>
	item.size > 0 && item.name?.length < 100 && (item.type.includes('video') ? true : item.size < MAX_FILE_SIZE_LIMIT);

const acceptedFileTypes = [...COMMUNITY_DOCUMENTS_EXCLUDE_IMAGE.availableMimeTypes].reduce((acc, type) => {
	return { ...acc, [type]: [] };
}, {});
const acceptedAllFileTypes = [...COMMUNITY_DOCUMENTS.availableMimeTypes, VIDEOS_MIME_TYPE].reduce((acc, type) => {
	return { ...acc, [type]: [] };
}, {});

const acceptedMediaTypes = [IMAGES_MIME_TYPE, VIDEOS_MIME_TYPE].reduce((acc, type) => {
	return { ...acc, [type]: [] };
}, {});
