import { useMe, useMemo } from '@hooks';
import {
	isEmpty,
	getNetworksNotificationState,
	deriveCommunityNotificationSettings,
	filterCurrentNotificationSettings,
} from '@utils';
import type { TSupportedChannel } from '@typings';
import { useNotificationsSettings } from '@ui-modules/settings/hooks/useNotificationsSettings';
import { useCreateNotificationSetting } from '@ui-modules/settings/hooks/useCreateNotificationSettings';
import { useUpdateNotificationSettings } from '@ui-modules/settings/hooks/useUpdateNotificationSettings';
import { NOTIFICATION_CHANNELS } from '@constants';

export const useCommunityNotifications = ({ communityId }: { communityId: string | 'learn' }) => {
	const { user } = useMe();
	const groupName = communityId === 'learn' ? 'Learn' : 'Networks';

	const { notificationsSettings, isLoadingNotificationsSettings, defaultsSettings } = useNotificationsSettings();

	const { mutate: createNotificationSetting, isLoading: isCreateMutationLoading } =
		useCreateNotificationSetting(groupName);

	const { mutate: updateNotificationSetting, isLoading: isUpdateMutationLoading } =
		useUpdateNotificationSettings(groupName);

	const currentNotificationSettings = useMemo(() => {
		return filterCurrentNotificationSettings(defaultsSettings, notificationsSettings, communityId);
	}, [defaultsSettings, notificationsSettings, communityId]);

	/**
	 * Handles the mutation of notifications settings.
	 * @param {TMutateNotifications} params - Parameters for mutation including alerts state and notification ID
	 */
	const mutateNotifications = async ({ alertsOn, notificationId, constraint = null }: TMutateNotifications) => {
		const channels = (
			alertsOn
				? notificationId !== 'new_comment'
					? NOTIFICATION_CHANNELS
					: NOTIFICATION_CHANNELS.filter((item) => item !== 'email')
				: []
		) as TSupportedChannel[];

		// Find the current settings based on notificationId and communityId
		const currentSetting = notificationsSettings?.find(
			(setting) =>
				setting.notificationId === notificationId &&
				(communityId === 'learn' ? true : setting.sourceId === communityId),
		);

		const settingExists = !isEmpty(currentSetting);

		const specificSettings = deriveCommunityNotificationSettings(communityId, constraint);

		try {
			if (settingExists) {
				updateNotificationSetting({
					id: currentSetting.id,
					notificationSetting: {
						channels,
						notificationId,
						...specificSettings,
					},
				});
			} else {
				createNotificationSetting({
					notificationId,
					owner: user['@id'],
					channels,
					...specificSettings,
				});
			}
		} catch (error) {
			console.error('Error mutating notifications:', error);
		}
	};

	const alertsOn = getNetworksNotificationState(currentNotificationSettings);

	return {
		mutateNotifications,
		isLoading: isUpdateMutationLoading || isCreateMutationLoading,
		alertsOn,
		isLoadingNotificationsSettings,
	};
};

export type TMutateNotifications = {
	alertsOn: boolean;
	notificationId: TNotificationId;
	constraint?: null | string | undefined;
};
export type TNotificationId = 'post_created' | 'learn_post_created' | 'new_comment' | 'new_comment_for_moderator';
