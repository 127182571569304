import CollapsibleText from '@ui-kit/components/CollapsibleText';
import { convertHighlightingTextToHtml } from '../Post.utils';
import { clsx, noop } from '@utils';
import styles from './PostContent.module.css';
import type { ICollapsibleTextProps } from '@ui-kit/components/CollapsibleText';

const PostContent = ({
	children,
	collapsedNumberOfLines = 5,
	highlightingText,
	className,
	onClickLink = (url: string) => window.open(url, '_blank', 'noopener,noreferrer'),
	onLinkClicked,
	...collapsibleTextProps
}: IPostContentProps) => {
	return (
		<CollapsibleText {...collapsibleTextProps} collapsedNumberOfLines={collapsedNumberOfLines}>
			<div
				className={clsx(styles.postContentWrapper, className)}
				dangerouslySetInnerHTML={{
					__html: highlightingText ? convertHighlightingTextToHtml(highlightingText, children) : children,
				}}
				onClick={(event) => {
					if (!('href' in event.target)) return;
					const url = String(event.target.href);
					onClickLink(url);
					onLinkClicked?.(url);
					event.stopPropagation();
				}}
				onKeyPress={noop}
			/>
		</CollapsibleText>
	);
};

export interface IPostContentProps
	extends Pick<
		ICollapsibleTextProps,
		| 'children'
		| 'collapsedTitle'
		| 'expandedTitle'
		| 'collapsedNumberOfLines'
		| 'onCollapse'
		| 'onExpand'
		| 'toggleHidden'
	> {
	children: string;
	className?: string;
	/** Substring to highlight with the boldest style as a search result. */
	highlightingText?: string;
	onClickLink?: (url: string) => void;
	onLinkClicked?: (url: string) => void;
}

export default PostContent;
