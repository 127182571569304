import type { Channel, MessageResponse, Attachment } from 'stream-chat';
import {
	Badge,
	MuteGroupIcon,
	ChatPhotoIcon,
	ChatFileIcon,
	ChatGiphyIcon,
	VideoAttachmentIcon,
	ChatEventIcon,
} from '@ui-kit';
import styles from './ChannelPreview.module.css';
import { useMe, useTranslation, useChat } from '@hooks';
import { clsx, toString } from '@utils';
import { formatLastMessagePreviewDate } from '@ui-modules/chat/utils/formatLastMessagePreviewDate';
import ChannelPreviewAvatar from '../ChannelPreviewAvatar';
import ChannelPreviewTitle from '../ChannelPreviewTitle';

import { useDraftAPI } from '@ui-modules/chat/hooks/useDraftAPI';
import { useMemo } from 'react';
import { getChatMessageAttachmentTypes } from './getChatMessageAttachmentTypes';

const ChannelPreview = ({ currentChannel, lastMessage, unread = 0, onClick }: IChannelPreviewProps) => {
	const { user } = useMe();
	const { selectedChannel } = useChat();
	const { t } = useTranslation();
	const isMessageHasEvent = !!lastMessage?.eventId;
	const { draftMessage } = useDraftAPI(currentChannel?.cid);
	const inputDraft = toString(draftMessage?.text);
	const inputAttachmentsDraft = draftMessage?.attachments ?? [];
	const inputEventsDraft = draftMessage?.selectedEvent;

	const lastMessageReportedId = ((currentChannel?.data?.lastReportedMessageId as TLastReportedMessage) || {})[user?.id];
	// need this because we need to show icon only once even if we have 5 images in last message
	const uniqueAttachmentTypes = getChatMessageAttachmentTypes(lastMessage);

	/**
	 * render attachment icon for all types of attachments only when we don't have any text message attached with last message
	 */
	const renderAttachmentIcon = (attachmentType: Attachment['type'], index: number) => {
		if (attachmentType === 'video') {
			return <VideoAttachmentIcon key={`video-attachment-${index}`} />;
		} else if (attachmentType === 'image') {
			return <ChatPhotoIcon height={16} key={`chat-photo-${index}`} width={16} />;
		} else if (attachmentType === 'file') {
			return <ChatFileIcon height={16} key={`chat-file-${index}`} width={16} />;
		} else if (attachmentType === 'giphy') {
			return <ChatGiphyIcon height={16} key={`chat-giphy-${index}`} width={16} />;
		} else {
			return <ChatEventIcon height={16} key={`chat-event-${index}`} width={16} />;
		}
	};

	const latestMessage = useMemo(() => {
		return lastMessage?.text ? lastMessage.text : '';
	}, [lastMessage?.text]);

	const lastMessageAttachments = () => {
		return (
			<div>
				{uniqueAttachmentTypes && uniqueAttachmentTypes.length > 0 ? (
					<>{uniqueAttachmentTypes?.map((item, index) => renderAttachmentIcon(item, index))}</>
				) : isMessageHasEvent ? (
					<ChatEventIcon />
				) : (
					<div className={styles.channelPreviewItem__lastMessage}>{latestMessage}</div>
				)}
			</div>
		);
	};
	return (
		<div
			className={clsx(
				currentChannel.id === selectedChannel?.id
					? styles.channelPreviewItem__container_selected
					: styles.channelPreviewItem__container,
			)}
			onClick={() => {
				onClick(currentChannel);
			}}
			onKeyDown={() => {
				onClick(currentChannel);
			}}
		>
			<ChannelPreviewAvatar channel={currentChannel} />
			<div className={styles.channelPreviewItem__nameAndLastMessageContainer}>
				<ChannelPreviewTitle channel={currentChannel} />
				{inputDraft?.length || inputAttachmentsDraft?.length || inputEventsDraft ? (
					<div className={styles.channelPreviewItem__draftMessage}>{t('Draft')}</div>
				) : lastMessage?.type === 'deleted' ? (
					<div className={styles.channelPreviewItem__lastMessage}>{t('This message was deleted...')}</div>
				) : (
					<>
						{lastMessageReportedId && lastMessageReportedId === lastMessage?.id ? (
							<div className={styles.channelPreviewItem__lastMessage}>{t('Message reported')}</div>
						) : (
							lastMessageAttachments()
						)}
					</>
				)}
			</div>
			<div className={styles.channelPreviewItem_dateContainer}>
				<div className={styles.channelPreviewItem__badgeAndMuteIconContainer}>
					<div>
						<Badge position="inline" value={unread} />
					</div>
					{currentChannel.muteStatus().muted ? (
						<div className={styles.channelPreviewItem__badgeContainer}>
							<MuteGroupIcon height={18} width={18} />
						</div>
					) : null}
				</div>
				<div
					className={
						currentChannel.muteStatus().muted || currentChannel.state.unreadCount > 0
							? styles.channelPreviewItem__dateWithbadge
							: styles.channelPreviewItem__date
					}
				>
					{lastMessage?.created_at ? formatLastMessagePreviewDate(t)(`${lastMessage?.created_at}`) : ''}
				</div>
			</div>
		</div>
	);
};

export default ChannelPreview;

export interface IChannelPreviewProps {
	currentChannel: Channel;
	lastMessage: MessageResponse | undefined;
	unread: number | undefined;
	onClick: (currentChannel: Channel) => void;
}

type TLastReportedMessage = { [key: string]: string };
