import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { SEARCH } from '@constants';

export interface IGlobalSearchState {
	searchQuery: string;
	isSearchActive: boolean;
}

const initialState: IGlobalSearchState = {
	searchQuery: '',
	isSearchActive: false,
};

export const globalSearchStateSlice = createSlice({
	name: 'globalSearchState',
	initialState,
	reducers: {
		// Search query.
		setSearchQuery: (state, { payload: searchQuery }: PayloadAction<string>) => {
			const prunedQuery = searchQuery.replace(SEARCH.sanitizingRegExp, '');
			state.searchQuery = prunedQuery;
		},
		resetSearchQuery: (state) => {
			state.searchQuery = '';
		},
		// Search activity.
		setIsSearchActive: (state, { payload: isSearchActive }: PayloadAction<boolean>) => {
			state.isSearchActive = isSearchActive;
		},
	},
});
