import { Page } from '../../components';
import {
	useParams,
	useService,
	useState,
	useMe,
	useNavigate,
	useChat,
	useEffect,
	useNotification,
	useTranslation,
	useCallback,
	useBlockedAndBlockingUsers,
	useQuery,
} from '@hooks';
import styles from './ChatChannelPage.module.css';
import { Avatar, Modal } from '@ui-kit';
import { Channel, MessageInput, Chat } from 'stream-chat-react';
import CustomMessage from '@ui-modules/chat/components/Message';
import CustomDateSeparator from '@ui-modules/chat/components/CustomDateSeparator';
import EmptyStateIndicator from '@ui-modules/chat/components/EmptyStateIndicator';
import { useGetChannelQuery } from '@ui-modules/chat/hooks/useGetChannelQuery';
import { MAXIMUM_ALLOWED_ATTACHMENTS_COUNT, ROUTES } from '@constants';
import ChatInputBar from '@ui-modules/chat/components/ChatInputBar';
import ChatMessageList from '@ui-modules/chat/components/ChatMessageList';
import AutocompleteSuggestionItem from '@ui-modules/chat/components/AutocompleteSuggestionItem';
import { useChannelFlaggedMessages } from '@ui-modules/chat/hooks/useChannelFlaggedMessages';
import { useDraftAPI } from '@ui-modules/chat/hooks/useDraftAPI';
import { retryPromise, toString } from '@utils';
import ChatChannelHeader from '@ui-modules/chat/components/ChatChannelHeader';

const ChatChannelPage = () => {
	const chat = useService('ChatService');
	const analytics = useService('AnalyticsService');
	const navigate = useNavigate();
	const { user } = useMe();
	const { showError, showInfo } = useNotification();
	const { t } = useTranslation();
	const [isModalShown, setIsModalShown] = useState(false);
	const { flagMessageIds, setFlaggedMessageIds } = useChannelFlaggedMessages();

	const { blockedUserRelations, usersBlockingMeRelations } = useBlockedAndBlockingUsers();

	const { setSelectedChannel, setChannelHasPausedMembers, setShouldUpdateChannel, selectedChannel } = useChat();

	const isTheOpponentInOneToOneChatIsBlocked =
		selectedChannel &&
		chat.checkIfOpponentInOneToOneChatIsBlocked(selectedChannel?.state.members, blockedUserRelations, user.slug);

	const isMeInOneToOneChatIsBlocked =
		selectedChannel &&
		chat.checkIfMeInOneToOneChatIsBlocked(selectedChannel?.state.members, usersBlockingMeRelations, user.slug);

	const chatLogicIsBlocked = isTheOpponentInOneToOneChatIsBlocked || isMeInOneToOneChatIsBlocked;

	const [channelName, setChannelName] = useState<string>();
	const channelId = useParams<IChatChannelPageParams>().channelId as string;

	if (channelId === user.slug) {
		navigate(-1);
		showError({ title: t('Chat'), subtitle: t('You can not create chat with your self') });
	}
	const { data: channel, isLoading: isLoadingOfChannelData } = useGetChannelQuery(channelId, {
		onSuccess: async (channel) => {
			if (channel) {
				// Because chat.hasChannelMembership() fails sometimes right after creation of a new chat, so we retry it a few times. I hate GetStream. [@DmitriyNikolenko]
				const hasChannelMembership = async () => await chat.hasChannelMembership(channel, user.slug);
				const retryingHasChannelMembership = retryPromise(hasChannelMembership, { retries: 3, minTimeout: 500 });

				if (!(await retryingHasChannelMembership)) {
					showInfo({ title: t('You are no longer a member of this chat') });
					navigate(ROUTES.chat(), { replace: true });
					return;
				}
				if (channel.data?.hidden) {
					await channel.show();
				}
				// To make sure channel is created and all set to use
				setSelectedChannel(channel);
				setShouldUpdateChannel(false);
				chat.queryPauseMembers(channel).then((members) => setChannelHasPausedMembers(members.length > 0));
				chat.getChannelNameForMessageScreen(channel, user.slug, t).then((channelName) => setChannelName(channelName));

				analytics.trackEvent('ChatInteractions', {
					chat_type: `${chat.getChatType(channel)}-chat`,
					channel_id: channelId,
					contact_id: chat.getOneToOneChatAnotherUserId(channel, user.id),
				});
			}
		},
	});
	const { data: isChannelMemberAdmin = false, isLoading: isChannelMemberAdminLoading } = useQuery(
		['chat.getChannelAdmin', channel?.id, user.slug],
		async () => await chat.getChannelAdmin(channel!, user.slug),
		{
			enabled: !!channel,
		},
	);

	useEffect(() => {
		if (selectedChannel) {
			chat.queryChannelFlaggedMessages(selectedChannel.cid, user.slug).then((messageIds) => {
				setFlaggedMessageIds(messageIds);
			});
			chat
				.getChannelNameForMessageScreen(selectedChannel, user.slug, t)
				.then((channelName) => setChannelName(channelName));
		}
	}, [selectedChannel]);

	// For `<ReactionSelector>` to work inside custom messages, 'react' action must be passed to `<MessageList>`.
	// TODO: Check with Haresh if he prefers using 'quote' action provided by Stream for implmenting "reply".
	// What we call 'reply' at Tiger21 is called `quote` in Stream.
	// What Stream calls `reply` is actually a thread. We don't have threads in Tiger21.
	// For actions available out of the box, see `MessageActionsArray`.
	// For adding custom actions, see https://getstream.io/chat/docs/sdk/react/components/core-components/message_list/#custommessageactions

	const ChatInput = useCallback(
		() => (
			<ChatInputBar
				cid={selectedChannel?.cid}
				disabled={chatLogicIsBlocked || false}
				onMaximumFilesAmountReached={() => setIsModalShown(true)}
			/>
		),
		[chatLogicIsBlocked, selectedChannel?.cid],
	);

	const renderCustomMessage = () => {
		const isOneToOneChat = selectedChannel ? chat.isOneToOne(selectedChannel) : false;
		return (
			<CustomMessage
				chatLogicIsBlocked={chatLogicIsBlocked}
				flagMessageIds={flagMessageIds}
				isOneToOneChat={isOneToOneChat}
			/>
		);
	};
	const { draftMessage } = useDraftAPI(selectedChannel?.cid as string);

	return (
		<Page title={channelName ? `${channelName}` : ''}>
			<Modal
				confirmTitle={t('OK')}
				subTitle={t("You can't attach more then {{count}} items", { count: MAXIMUM_ALLOWED_ATTACHMENTS_COUNT })}
				title={t('Maximum number of files reached')}
				visible={isModalShown}
				onConfirm={() => setIsModalShown(false)}
			/>
			<div className={styles.channel__pageWrapper}>
				<div className={styles.channel__chatListWrapper}>
					<Chat
						client={chat.chatClient}
						/* 'ReactionsListModalAvatar' props is available owing to PATCH for "T21C-7386 render custom Avatar in ReactionListModal" [@DmitriyNikolenko] */
						ReactionsListModalAvatar={({ name, user }) => {
							return (
								<Avatar imageUri={user.avatar} outline={user.roles.includes('ROLE_CHAIR')} size={26} title={name} />
							);
						}}
					>
						{selectedChannel && flagMessageIds !== undefined && (
							<Channel
								AutocompleteSuggestionItem={AutocompleteSuggestionItem}
								channel={selectedChannel}
								DateSeparator={({ date }) => <CustomDateSeparator date={date} />}
								EmptyStateIndicator={EmptyStateIndicator}
								Message={renderCustomMessage}
								// ./temp/stream-chat-react
							>
								<div className={styles.channel__messageListContainer}>
									{channel ? (
										<ChatChannelHeader
											channel={channel}
											channelName={channelName}
											isChannelMemberAdmin={isChannelMemberAdmin}
											isLoading={isLoadingOfChannelData || isChannelMemberAdminLoading}
										/>
									) : (
										<div className={styles.header__plug} />
									)}
									<ChatMessageList />
									<MessageInput
										additionalTextareaProps={{ defaultValue: toString(draftMessage?.text) }}
										disabled={chatLogicIsBlocked || false}
										grow
										Input={ChatInput}
										maxRows={5}
									/>
								</div>
							</Channel>
						)}
					</Chat>
				</div>
			</div>
		</Page>
	);
};

export interface IChatChannelPageParams extends Record<string, string | undefined> {
	channelId: string;
}

export default ChatChannelPage;
