import { clsx } from '@utils';
import styles from './Input.module.css';
import InputErrorMessage from '../InputErrorMessage';
import InputLabel from '../InputLabel';
import { useId } from 'react';
import type { ReactNode } from 'react';

const Input = ({
	value,
	placeholder,
	disabled,
	icon,
	iconPosition,
	label,
	errorMessage,
	errorMessagePosition = 'left',
	explanation,
	secureTextEntry = false,
	ariaLabel,
	keepMarginBottom,
	subInputElement = null,
	errorMessageInitialHidden,
	...props
}: IInputProps) => {
	const inputId = useId();

	return (
		<div className={styles.input__wrapper}>
			<InputLabel htmlFor={inputId} text={label} />
			{explanation ? <span className={styles.input__explanation}>{explanation}</span> : null}
			<div className={clsx(styles.input__container, styles.input__wrapper)}>
				{icon && iconPosition === 'left' ? (
					<span className={clsx(styles.input__icon, styles.input__icon_left)}>{icon}</span>
				) : null}
				<input
					aria-label={ariaLabel || String(label)}
					className={clsx(
						styles.input,
						!!errorMessage && styles.input_error,
						icon && iconPosition === 'left' && styles.input__icon_leftPosition,
						icon && iconPosition === 'right' && styles.input__icon_rightPosition,
					)}
					disabled={disabled}
					id={inputId}
					placeholder={placeholder}
					type={secureTextEntry ? 'password' : 'text'}
					value={value}
					{...props}
				/>
				{icon && iconPosition === 'right' ? (
					<span className={clsx(styles.input__icon, styles.input__icon_right)}>{icon}</span>
				) : null}
			</div>

			{errorMessagePosition !== 'none' && (
				<div
					className={clsx(
						styles.input__errorMessageBox,
						errorMessagePosition === 'right' && styles.input__errorMessageBox_right,
					)}
				>
					<div>
						<InputErrorMessage
							hidden={errorMessageInitialHidden && !errorMessage}
							keepMarginBottom={keepMarginBottom}
							text={errorMessage}
						/>
					</div>
					<div>{subInputElement}</div>
				</div>
			)}
		</div>
	);
};

export interface IInputProps
	extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	value?: string;
	placeholder?: string;
	explanation?: string;
	secureTextEntry?: boolean;
	disabled?: boolean;
	label?: string | ReactNode;
	icon?: ReactNode;
	iconPosition?: 'left' | 'right';
	ariaLabel?: string;
	errorMessage?: string;
	errorMessagePosition?: 'left' | 'right' | 'none';
	/** By default the error message place is always visible and uses for displaying without layout shift. If 'true' after appearing an error message the margin between message and bottom will appear. Default 'false'. */
	keepMarginBottom?: boolean;
	/** React element which will be rendered on the right side of the input error message. Default null. */
	subInputElement?: ReactNode;
	/** By default error message have a height if no error present. */
	errorMessageInitialHidden?: boolean;
}

export default Input;
