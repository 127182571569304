import { useService, useDebounce, useInfiniteQuery, useMemo, useEffect } from '@hooks';
import { dayjs, noop } from '@utils';
import type { TAlgoliaSearchResponse, TCommunity, TDeal, TSearchDeals } from '@typings';

export const useDealsSearchQuery = ({ searchQuery, communityId, onSearch }: IUseDealsSearchQuery) => {
	const algoliaSearch = useService('AlgoliaSearchService');
	const [preparedSearchQuery] = useDebounce(searchQuery.trim(), 300);
	const isSearchEnabled = preparedSearchQuery.length >= 3;
	useEffect(() => {
		const searchedQuery = isSearchEnabled ? preparedSearchQuery : '';
		onSearch(searchedQuery);
	}, [preparedSearchQuery]);

	const infiniteQuery = useInfiniteQuery<TAlgoliaSearchResponse<TSearchDeals>, Error>(
		['algoliaSearch.searchByFeedActivities', communityId, preparedSearchQuery],
		async ({ pageParam = 0 }) =>
			await algoliaSearch.searchByDeals({
				searchQuery: preparedSearchQuery,
				page: pageParam,
				communityId,
			}),
		{
			staleTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			cacheTime: dayjs.duration(10, 'seconds').asMilliseconds(),
			enabled: isSearchEnabled,
			getNextPageParam: (lastPage) => {
				const isPagePresent = typeof lastPage.page === 'number';
				const maybeNextPage = lastPage.page + 1;
				const areMorePages = maybeNextPage < lastPage.nbPages;
				if (isPagePresent && areMorePages) return maybeNextPage;
				return undefined;
			},
		},
	);
	const data = useMemo<TDeal[]>(
		function normalizeData() {
			return (infiniteQuery.data?.pages.flatMap((page) =>
				page.hits.map((searchDeal) => adaptSearchDealToDeal(searchDeal)),
			) ?? EMPTY_RESPONSE) as TDeal[];
		},
		[infiniteQuery.data],
	);

	const fetchMore = infiniteQuery.hasNextPage ? infiniteQuery.fetchNextPage : noop;

	return {
		preparedSearchQuery,
		isSearchEnabled,
		...infiniteQuery,
		data,
		fetchMore,
	};
};

interface IUseDealsSearchQuery {
	searchQuery: string;
	communityId: TCommunity['id'] | null;
	onSearch: (searchQuery: string) => void;
}

const EMPTY_RESPONSE = undefined;

const adaptSearchDealToDeal = (searchDeal: TSearchDeals): TDeal => {
	return {
		'@id': `api/deals/${searchDeal.objectID}`,
		'@type': 'Deal',
		activityId: searchDeal.streamActivityId,
		id: searchDeal.objectID,
		closingDate: searchDeal.closingDate,
		status: searchDeal.status,
		commentsCount: searchDeal.replies_count,
		likesCount: searchDeal.likes_count,
		creatorType: searchDeal.creator.type,
		currency: searchDeal.currency,
		createdAt: searchDeal.createdAt,
		updatedAt: searchDeal.updatedAt,
		description: searchDeal.description,
		name: searchDeal.name,
		community: {
			id: searchDeal.communityId,
			'@id': searchDeal.communityIri,
			definition: 'network',
			name: searchDeal.communityName,
			shortName: searchDeal.communityName,
			slug: 'string',
			description: 'string',
		},
		creator: {
			'@id': searchDeal.creator.iri,
			avatarUrl: searchDeal.creator.avatar ?? undefined,
			communitiesForBadge: undefined,
			firstName: searchDeal.creator.firstName,
			lastName: searchDeal.creator.lastName,
			id: searchDeal.creator.id,
			profileIri: searchDeal.creator.profileIri,
			fullName: `${searchDeal.creator.firstName} ${searchDeal.creator.lastName}`,
		},
		data: searchDeal.data,
		dealAssetClass: {
			'@id': 'string',
			name: searchDeal.dealAssetClass,
		},
		dealSubClass: {
			'@id': 'string',
			name: searchDeal.dealSubClass,
		},
		dealType: {
			'@id': 'string',
			name: searchDeal.dealType,
		},
		dealName: searchDeal.name,
		minInvestment: searchDeal.minInvest,
		webUrls: [],
		videoUrl: '',
		contactName: '',
		creatorEmail: '',
		creatorPhone: '',
		contactEmail: '',
		contactPhone: '',
		streamActivityId: searchDeal.streamActivityId,
		alerts: false, // @deprecated It is non-existing property added by guess before backend was ready. Use currentDealMemberRelation.newPostAlerts instead.
		compensationDisclosure: false,
	};
};
