import EventNotificationAvatarSource from '../../assets/event_notification_avatar.png';
import NetworkNotificationAvatarSource from '../../assets/network_notification_avatar.png';
import NominationNotificationAvatarSource from '../../assets/nomination_notification_avatar.png';
import DealNotificationAvatarSource from '../../assets/deal_notification_avatar.png';
import GroupNotificationAvatarSource from '../../assets/group_notification_avatar.png';
import { useMyTimezone, useTranslation } from '@hooks';
import { dayjs } from '@utils';
import uniqBy from 'lodash/uniqBy';
import type { INotificationFeedActivity, TNotificationFeedActivityVerb } from '@typings';
import type { ValueIteratee } from 'lodash';

export const pickUpNotificationAvatar = (notification: INotificationFeedActivity): string | undefined => {
	switch (notification.verb) {
		case 'event_updated':
		case 'new_event':
		case 'meeting_reminder':
			return EventNotificationAvatarSource;
		case 'network_approved':
			return NetworkNotificationAvatarSource;
		case 'deal_on_review':
		case 'deal_updated_by_third_party':
			return DealNotificationAvatarSource;
		case 'nomination_submitted':
		case 'nomination_declined':
		case 'nomination_accepted':
			return NominationNotificationAvatarSource;
		case 'aa_comparison_available':
			return GroupNotificationAvatarSource;
		default: {
			return notification?.actor?.data?.avatar ?? undefined;
		}
	}
};

export const isVerbWithOutline = (verb: TNotificationFeedActivityVerb) => {
	switch (verb) {
		case 'event_updated':
		case 'new_event':
		case 'meeting_reminder':
		case 'network_approved':
		case 'nomination_submitted':
		case 'nomination_declined':
		case 'nomination_accepted':
		case 'deal_on_review':
			return false;
		default: {
			return true;
		}
	}
};

const useParseDateNotificationDate = (content: string) => {
	const { timezone } = useMyTimezone();
	const dateRegex = /\d{2}([/-])\d{2}\1\d{4}\s(\d{1,2}:\d{2})(\s[ap]m)/gi;
	const format = 'MM-DD-YYYY hh:mm a';
	const result = dateRegex.exec(content);
	if (result) {
		const dateTime = result[0];
		const utc = dayjs.utc(dateTime, format);
		const local = dayjs.tz(utc, timezone).format(format);
		return content.replace(dateRegex, local);
	}
	return content;
};

const sanitizeBoldText = (content: string) => {
	const boldRegex = /\*\*(.*?)\*\*/g;
	const result = boldRegex.exec(content);
	if (result && result[1]) {
		return content.replace(result[0], `**${result[1].trim()}**`);
	}
	return content;
};

export const useFormattedMessage = (
	content: string,
	isReply: boolean,
	activities: INotificationFeedActivity[],
	isDealReply: boolean,
	isNetworkConnectionReply: boolean,
) => {
	let formattedMessage = content;
	const uniqActivities = uniqBy(activities, 'actor.id' as ValueIteratee<INotificationFeedActivity>);
	const { t } = useTranslation();
	if ((isReply || isDealReply || isNetworkConnectionReply) && uniqActivities.length > 1) {
		// eslint-disable-next-line clean-regex/confusing-quantifier
		const regex = /(\*?)+([^.*?$])+(\**)/g;
		const result = regex.exec(content);
		if (result) {
			const actor = result[0];
			const restMessage = content.slice(actor.length).trim();
			const othersCount = uniqActivities.length - 1;
			formattedMessage = `${actor} ${t('and {{count}} other', { count: othersCount })} ${restMessage}`;
		}
	}
	return sanitizeBoldText(useParseDateNotificationDate(formattedMessage));
};
