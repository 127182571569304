import React, { useState, useEffect } from 'react';
import AvatarGroup from '@ui-modules/chat/components/AvatarGroup';
import { useService, useMe, useTranslation } from '@hooks';
import type { Channel as TChannel } from 'stream-chat';
import { Avatar, DealsIcon } from '@ui-kit';
import styles from './ChannelPreviewAvatar.module.css';
import type { TChatUser } from '@typings';
import { getRandomItemForTitle } from '@utils';
import { CHAT_DEALS_ICON_BG_COLORS } from '@constants';

interface IChannelProps {
	channel: TChannel;
}

const ChannelPreviewAvatar: React.FC<IChannelProps> = ({ channel }) => {
	const chat = useService('ChatService');
	const { t } = useTranslation();
	const { user } = useMe();
	const [otherUser, setOtherUser] = useState<TChatUser | null>(null);

	useEffect(() => {
		async function fetchChannelName() {
			if (chat.isOneToOneWithOneMember(channel)) {
				let otherMemberId = chat.extractOtherOneToOneMemberId(channel, user.slug);
				if (channel && channel.data && 'memberIDs' in channel.data) {
					const memberIds = channel.data.memberIDs as string[];
					otherMemberId = memberIds.filter((id) => id !== user.slug)[0];
				}
				const userObj = await chat.queryUser(otherMemberId);
				if (userObj) {
					setOtherUser(userObj as TChatUser);
				}
			}
		}
		fetchChannelName();
	}, [channel.id]);

	return (
		<div>
			{chat.isDealChat(channel) ? (
				<div
					className={styles.channelPreviewAvatar__dealChatAvatar}
					style={{ backgroundColor: getRandomItemForTitle(CHAT_DEALS_ICON_BG_COLORS, String(channel.id)) }}
				>
					<DealsIcon fill="white" height={18} width={23} />
				</div>
			) : chat.isOneToOneWithOneMember(channel) ? (
				<Avatar
					imageUri={otherUser?.avatar as string | null}
					isInactive={otherUser?.roles?.includes('ROLE_INACTIVE')}
					outline={Boolean(otherUser?.roles?.includes('ROLE_CHAIR'))}
					size={40}
					title={otherUser?.name}
				/>
			) : chat.isOneToOne(channel as any as TChannel) ? (
				<>
					<Avatar
						imageUri={chat.getOneToOneChatUserAvatar(channel.state.members, user.slug)}
						isInactive={chat.isInactiveUser(channel.state.members, user.slug)}
						outline={Boolean(chat.isChair(channel.state.members, user.slug))}
						size={40}
						title={chat.getOneToOneChatUserName(channel.state.members, user.slug, t)}
					/>
				</>
			) : (
				<AvatarGroup channel={channel as any as TChannel} />
			)}
		</div>
	);
};

export default React.memo(ChannelPreviewAvatar);
