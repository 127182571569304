import styles from './Card.module.css';
import type { ButtonHTMLAttributes, DetailedHTMLProps, ElementType, ReactNode } from 'react';
import { clsx } from '@utils';

/** Header part of the Card component. */
const CardHeader = ({
	title,
	backgroundPlacement = 'card',
	iconElement,
	actionElement,
	titleAs: TitleElement = 'h3',
	children,
	className,
	headerButtonProps,
	iconPosition = 'right',
	count,
	cardContentClassName,
}: ICardHeaderProps) => {
	const headerElement = (
		<header className={clsx(iconPosition === 'left' ? styles.card__headerWithLeftIcon : styles.card__header)}>
			{iconPosition === 'left' && (
				<div
					className={clsx(iconPosition === 'left' ? styles.card__actionLeft : styles.card__action)}
					onClick={(e) => e.stopPropagation()}
					onKeyDown={(e) => e.stopPropagation()}
				>
					{actionElement}
				</div>
			)}
			<div className={styles.card__icon}>{iconElement}</div>
			<div className={styles.card__titleBox}>
				<TitleElement className={styles.card__title}>{count ? `${title} (${count})` : title}</TitleElement>
			</div>
			{iconPosition === 'right' && (
				<div
					className={styles.card__action}
					onClick={(e) => e.stopPropagation()}
					onKeyDown={(e) => e.stopPropagation()}
				>
					{actionElement}
				</div>
			)}
		</header>
	);

	return (
		<section className={clsx(styles.card, className)} data-background-placement={backgroundPlacement}>
			{headerButtonProps ? (
				<button
					{...headerButtonProps}
					aria-label="toggle"
					className={clsx(styles.card__button, iconPosition === 'left' && styles.card__buttonWLeftIcon)}
				>
					{headerElement}
					{iconPosition === 'left' && <div className={styles.card__line} />}
				</button>
			) : (
				headerElement
			)}
			<div
				className={clsx(
					iconPosition === 'left' ? styles.card__contentWithLeftIcon : styles.card__content,
					cardContentClassName,
				)}
			>
				{children}
			</div>
		</section>
	);
};

export interface ICardHeaderProps {
	/** Title text */
	title: string;
	/** ReactNode content rendered under the CardHeader. */
	children: ReactNode;
	/** Where to show the white background, Default 'card'. Cdn be 'card' or 'article'. */
	backgroundPlacement?: 'card' | 'article' | 'none';
	/** ReactNode showing as an Icon to the left of the title. */
	iconElement?: ReactNode;
	/** Element to use as title. Default 'h3'. */
	titleAs?: ElementType;
	/** ReactNode showing as a Button/Link to the right of the title. */
	actionElement?: ReactNode;
	/** Card class name */
	className?: string;
	cardContentClassName?: string;
	/* Header button props */
	headerButtonProps?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
	/* dropdown icon position */
	iconPosition?: 'right' | 'left';
	/** The count value of files/members/etc */
	count?: number;
}

export default CardHeader;
