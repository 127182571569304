import { useRef, useState, useInterval } from '@hooks';
import { dayjs } from '@utils';
import type { TIsoDate } from '@typings';

/** Shows relative time and automatically updates to show fresh time. */
const RelativeTime = ({ dateString, className }: IRelativeTimeProps) => {
	const todayDate = useRef(dayjs.tz(dayjs().toISOString(), 'UTC')).current;
	const targetDate = useRef(dayjs.tz(dateString, 'UTC')).current;
	const [relativeTime, setRelativeTime] = useState(() => targetDate.fromNow(true));

	// Update every minute if difference is less than an hour.
	const delay = useRef(dayjs.duration(1, 'minute').asMilliseconds()).current;
	const isRunning = Math.abs(todayDate.diff(targetDate, 'minutes')) <= 60;
	useInterval(
		() => {
			setRelativeTime(targetDate.fromNow());
		},
		isRunning ? delay : null,
	);

	return <small className={className}>{relativeTime}</small>;
};

export interface IRelativeTimeProps {
	/** Current date. */
	dateString: TIsoDate;
	/** Custom class name for time text component. */
	className?: string;
}

export default RelativeTime;
