import { Post } from '@ui-kit';
import FeedActivityWrapper from './FeedActivityWrapper';
import PostVideos from '@ui-modules/feed/components/PostVideos';
import PostEvent from '@ui-modules/feed/components/PostEvent';
import PostLinksPreview from '@ui-modules/feed/components/PostLinksPreview';
import FeedBanner from '@ui-modules/feed/components/FeedBanner';
import FeedAdVideo from '@ui-modules/feed/components/FeedAdVideo';
import { useRef, useToggle, useTranslation } from '@hooks';
import { useMutatePostMeatballMenuOptions } from '@ui-modules/feed/hooks/useMutatePostMeatballMenuOptions';
import { useToggleActivityLikeMutation } from '@ui-modules/feed/hooks/useToggleActivityLikeMutation';
import { useToggleActivityBookmarkMutation } from '@ui-modules/feed/hooks/useToggleActivityBookmarkMutation';
import { useHandleActivityLink } from '@ui-modules/feed/hooks/useHandleActivityLink';
import { ROUTES } from '@constants';
import { clsx, ActivityEntity } from '@utils';
import styles from './FeedActivity.module.css';
import type { TActivity, TActivityInteractionSource, TEvent } from '@typings';
import type { ActivityProps } from 'react-activity-feed';
import type { RefObject } from 'react';

/** Single Post of a Feed.
 * 	Uses ui-kit Post primitive component adapted to work with react-activity-feed FlatFeed as a layer of abstraction.
 */
const FeedActivity = ({
	activity,
	isCard,
	isSourceHidden,
	feedUserId,
	withSeparator = false,
	showBookmark = false,
	isAlwaysExpanded = false,
	canDeletePost = false,
	activityContentLink: customActivityContentLink,
	onPressActivityLink,
	onClickComment,
	onClickReply,
	onClickEvent,
	onActivityDeleted,
	onFileClick,
	onInteracted,
	onVideoPlayed,
	onLinkClicked,
	onImageOpened,
	isMeatBallMenuVisible = true,
	showFooter = true,
	disableLinkNavigation = false,
}: IFeedActivityProps) => {
	const { t } = useTranslation();
	const activityEntity = new ActivityEntity(activity);
	const postRef = useRef<HTMLElement>() as RefObject<HTMLElement>;
	const scrollIntoPostView = () => postRef.current?.scrollIntoView(true);

	// Menu handlers.
	const [isDeleteActivityDialogVisible, toggleDeleteActivityDialog] = useToggle(false);
	const meatballMenuOptions = useMutatePostMeatballMenuOptions(
		activityEntity,
		toggleDeleteActivityDialog,
		!isMeatBallMenuVisible,
		feedUserId,
	);

	// Action handlers.
	const { mutate: toggleLike } = useToggleActivityLikeMutation();
	const { mutate: toggleBookmark } = useToggleActivityBookmarkMutation();
	const handleClickComment = () => {
		onClickComment(activity.id);
		onInteracted?.('comments');
	};

	const handleClickReply = () => {
		onClickReply(activity.id);
		onInteracted?.('reply');
	};
	const handleLike = () => {
		toggleLike(activity);
		onInteracted?.('like');
	};
	const activityContentLink = customActivityContentLink ?? ROUTES.post(activity.id, feedUserId);
	const profileLink = activityEntity.isInactive ? undefined : ROUTES.memberProfile(activityEntity.profileId);
	const openLink = useHandleActivityLink();
	const sourceLink = getSourceLink(activityEntity);

	if (activity.settings.postType === 'pinnedBanner') return null; // We don't need to load pinnedBanner in feed because we are getting them from symphony backend
	if (activity.settings.postType === 'adImage')
		return (
			<FeedActivityWrapper
				activity={activity}
				isDeleteActivityDialogVisible={isDeleteActivityDialogVisible}
				toggleDeleteActivityDialog={toggleDeleteActivityDialog}
				onActivityDeleted={onActivityDeleted}
			>
				<div className={clsx(withSeparator && styles.feedActivity__post)}>
					<FeedBanner
						canDeletePost={canDeletePost}
						className={styles.adImage}
						imageUrl={activity.settings.adImage.image}
						link={activity.settings.adImage.link}
						onClick={(link) => {
							onPressActivityLink(link, activity);
							onInteracted?.('card body');
						}}
						onDelete={() => toggleDeleteActivityDialog(true)}
					/>
				</div>
			</FeedActivityWrapper>
		);
	if (activity.settings.postType === 'adVideo') {
		return (
			<FeedActivityWrapper
				activity={activity}
				isDeleteActivityDialogVisible={isDeleteActivityDialogVisible}
				toggleDeleteActivityDialog={toggleDeleteActivityDialog}
				onActivityDeleted={onActivityDeleted}
			>
				<div className={clsx(withSeparator && styles.feedActivity__post)}>
					<FeedAdVideo
						adVideo={activity.settings.adVideo}
						canDeletePost={canDeletePost}
						title={activity.subject}
						onClick={(link) => {
							onPressActivityLink(link, activity);
							onInteracted?.('button');
						}}
						onDelete={() => toggleDeleteActivityDialog(true)}
						onVideoPlayed={(videoUrl) => {
							onVideoPlayed?.(videoUrl);
							onInteracted?.('video');
						}}
					/>
				</div>
			</FeedActivityWrapper>
		);
	}
	return (
		<FeedActivityWrapper
			activity={activity}
			isDeleteActivityDialogVisible={isDeleteActivityDialogVisible}
			toggleDeleteActivityDialog={toggleDeleteActivityDialog}
			onActivityDeleted={onActivityDeleted}
		>
			<Post
				className={clsx(withSeparator && styles.feedActivity__post)}
				disableLinkNavigation={disableLinkNavigation}
				footerElement={
					showFooter && (
						<Post.PostActionBar
							bookmarked={activityEntity.bookmarked}
							comments={activityEntity.comments}
							hasMyLike={activityEntity.hasMyLike}
							likes={activityEntity.likes}
							likeTitle={t('Like')}
							replyTitle={t('Reply')}
							showBookmark={showBookmark || activityEntity.shouldShowBookmark()}
							onBookmark={() => {
								toggleBookmark(activity);
								onInteracted?.('bookmark');
							}}
							onClickComment={handleClickComment}
							onClickReply={handleClickReply}
							onLike={handleLike}
						/>
					)
				}
				headerElement={
					<Post.Header
						avatarProps={{
							imageUri: activityEntity.avatarUri,
							outline: activityEntity.isChair,
							size: 40,
							isInactive: activityEntity.isInactive,
							onClick: () => {
								onInteracted?.('avatar');
							},
						}}
						createdAt={activityEntity.createdAt}
						profileLink={profileLink}
						sourceLink={sourceLink}
						sourceName={isSourceHidden ? undefined : activityEntity.determineSourceName(t)}
						userId={activity?.actor?.data?.userId}
						userName={activityEntity.getDisplayingUserName(t)}
						onProfileClicked={() => {
							onInteracted?.('profile');
						}}
						onSourceNameClicked={() => {
							onInteracted?.('source');
						}}
					/>
				}
				isCard={isCard}
				meatballMenuOptions={meatballMenuOptions}
				postContentLink={activityContentLink}
				ref={postRef}
				withFooterSeparator={showFooter}
				onClick={() => onInteracted?.('card body')}
				onMenuOpened={() => onInteracted?.('menu')}
			>
				<>
					{activityEntity.title ? <Post.Title>{activityEntity.title}</Post.Title> : null}
					{activityEntity.content?.trim() ? (
						<Post.Content
							collapsedNumberOfLines={getContentCollapsedNumberOfLines(isAlwaysExpanded)}
							collapsedTitle={t('Read more')}
							expandedTitle={t('Show less')}
							onClickLink={(url) => {
								openLink(url);
								onInteracted?.('link');
							}}
							onCollapse={() => {
								scrollIntoPostView();
								onInteracted?.('collapse');
							}}
							onExpand={() => onInteracted?.('expand')}
							onLinkClicked={(url) => {
								onLinkClicked?.(url);
							}}
						>
							{activityEntity.htmlContent}
						</Post.Content>
					) : null}
					{activityEntity.links?.length ? (
						<PostLinksPreview
							links={activityEntity.links}
							onLinkClicked={(url) => {
								onLinkClicked?.(url);
								onInteracted?.('link');
							}}
						/>
					) : null}
					{activityEntity.files?.length ? (
						<Post.Attachments
							attachmentUrls={activityEntity.files}
							onClick={(url) => {
								onFileClick(url);
								onInteracted?.('file');
							}}
						/>
					) : null}
					{activityEntity.images?.length ? (
						<Post.ImageGallery
							avatarOutline={activityEntity.isChair}
							createdAt={activityEntity.createdAt}
							images={activityEntity.images}
							imageUri={activityEntity.avatarUri}
							sourceName={activityEntity.determineSourceName(t)}
							userId={activity?.actor?.data?.userId}
							userName={activityEntity.userName}
							onImageOpened={(imageUrl: string) => {
								onImageOpened?.(imageUrl);
								onInteracted?.('image');
							}}
						/>
					) : null}
					{activityEntity.videos?.length ? (
						<PostVideos
							videos={activityEntity.videos}
							onVideoPlayed={(videoUrl) => {
								onVideoPlayed?.(videoUrl);
								onInteracted?.('video');
							}}
						/>
					) : null}
					{activityEntity.eventId ? (
						<PostEvent
							eventId={activityEntity.eventId}
							onClick={(event) => {
								onClickEvent(event, activity);
								onInteracted?.('event');
							}}
						/>
					) : null}
					{activityEntity.button ? (
						<Post.Button
							title={activityEntity.button.text}
							onClick={() => {
								onPressActivityLink(activityEntity.button.link, activity);
								onInteracted?.('button');
							}}
						/>
					) : null}
				</>
			</Post>
		</FeedActivityWrapper>
	);
};

const getSourceLink = (activityEntity: ActivityEntity) =>
	activityEntity.isFromNetworkDiscussion()
		? ROUTES.network(activityEntity.communityId)
		: activityEntity.isFromLearn()
		? ROUTES.learnTab(activityEntity.getLearnFeedRoutePath())
		: '#';

function getContentCollapsedNumberOfLines(alwaysExpanded: boolean) {
	return alwaysExpanded ? Number.MAX_SAFE_INTEGER : 5;
}

export interface IFeedActivityProps extends Omit<ActivityProps, 'activity'> {
	/** Activity to render. */
	activity: TActivity;
	/** IF true the name of a community or 'Learn' is hidden. Default 'false'. */
	isSourceHidden: boolean;
	/** Should the item be separated with bottom-margin. */
	withSeparator: boolean;
	/** Should it be rendered as an independent card (if true) or a list ros (if false). */
	isCard: boolean;
	/** Defines if the 'Read more'/'Show less' link should be hidden. Default 'false'. */
	isAlwaysExpanded?: boolean;
	/** Stream feed userId to properly build link to open post on click.
	 * @tutorial pass @prop feedUserId to make navigation work for Learn posts.
	 */
	feedUserId?: string;
	/** Should the bookmark button be shown. Default 'false'. */
	showBookmark?: boolean;
	/** Should the delete post button be shown. Default 'false'. */
	canDeletePost?: boolean;
	onPressActivityLink: (buttonLink: string, activity: TActivity) => void;
	onClickComment: (activityId: TActivity['id']) => void;
	onClickReply: (activityId: TActivity['id']) => void;
	onClickEvent: (event: TEvent, activity: TActivity) => void;
	onFileClick: (url: string) => void;
	onActivityDeleted?: () => void;
	/** To use with analytics */
	onInteracted?: (interactionSource: TActivityInteractionSource) => void;
	/** Callback fired when video is played. */
	onVideoPlayed?: (videoUrl: string) => void;
	/** Callback fired when link is clicked. */
	onLinkClicked?: (url: string) => void;
	/** Callback fired when image is opened. */
	onImageOpened?: (imageUrl: string) => void;
	/** Boolean to show the meatball menu of each post or not */
	isMeatBallMenuVisible?: boolean;
	/** Custom URL to the single post screen or an empty url to prevent opening the post by default */
	activityContentLink?: string;
	showFooter?: boolean;
	/** URL to the single post screen can be disabled with this boolean passed as: True */
	disableLinkNavigation?: boolean;
}

export default FeedActivity;
