import { useService, useQuery } from '@hooks';
import { useMe } from './useMe';
import type { THidableProfileProperties, TProfile, TProfileWithUser } from '@typings';

export const useGetProfileQuery = (profileId: TProfile['id'] | undefined) => {
	const api = useService('ApiService');

	// Me profile.
	const { profile: myProfile, user: myUser } = useMe();
	const isMe = !profileId || myProfile.id === profileId;

	const myProfileWithUser: TProfileWithUser | undefined = isMe
		? {
				...myProfile,
				user: myUser,
		  }
		: undefined;
	const queryResult = useQuery(
		['profile.getProfile', profileId],
		async () => api.profile.getProfile(profileId as string),
		{
			enabled: !isMe,
			placeholderData: myProfileWithUser,
		},
	);

	const fullName = queryResult.data ? `${queryResult.data.user.firstName} ${queryResult.data.user.lastName}` : '';
	const isPropertyHidden = (section: THidableProfileProperties) => {
		return queryResult.data?.hideProperties?.includes?.(section) ?? false;
	};

	const isSectionInvisible = (section: TProfileSection) => {
		const profile = queryResult.data;
		if (!profile) {
			return true;
		} else if (profile.user.roles.includes('ROLE_FORMER')) {
			return !formerProfileSections.includes(section);
		} else if (profile['@type'] === 'ProfileStaff') {
			return !staffProfileSections.includes(section);
		} else if (profile['@type'] === 'ProfileChair') {
			return !chairProfileSections.includes(section);
		} else if (profile['@type'] === 'ProfileExecutive') {
			return !executiveProfileSections.includes(section);
		} else {
			return !memberProfileSections.includes(section);
		}
	};

	return {
		isMe,
		fullName,
		isPropertyHidden,
		isSectionInvisible,
		...queryResult,
	};
};

const staffProfileSections: TProfileSection[] = ['myRole'];
const executiveProfileSections: TProfileSection[] = ['myRole'];
const formerProfileSections: TProfileSection[] = ['biography'];
const chairProfileSections: TProfileSection[] = [
	'chairBiography',
	'chairInterests',
	'chairLeadsCommunities',
	'sinceInfo',
];
const memberProfileSections: TProfileSection[] = [
	'biography',
	'groups',
	'events',
	'activities',
	'expertise',
	'interests',
	'investmentExpertise',
	'biography',
	'links',
	'deals',
	'blockUser',
];

type TProfileSection =
	| THidableProfileProperties
	| keyof Pick<TProfile, 'myRole' | 'expertise' | 'interests' | 'investmentExpertise' | 'biography' | 'links'>
	| 'chairInterests'
	| 'chairBiography'
	| 'chairLeadsCommunities'
	| 'sinceInfo'
	| 'deals'
	| 'blockUser';
