import { useService, useQuery, useMe } from '@hooks';
import { dayjs, isUserFeatureEnabled } from '@utils';
import type { UseQueryOptions } from '@tanstack/react-query';
import type { TFlyoutResponse } from '@tiger21-llc/connect-shared/src/typings';

export const useFlyoutQuery = <T = TUseFlyoutQueryData>(
	params?: { page?: number },
	queryOptions?: UseQueryOptions<TUseFlyoutQueryData, Error, T>,
) => {
	const api = useService('ApiService');
	const reactQuery = useService('ReactQueryService');
	const { user } = useMe();

	return useQuery<TUseFlyoutQueryData, Error, T>(
		reactQuery.queryKeys.getFlyoutMessageAsRead(params?.page),
		() => api.flyout.getFlyoutMessages(params?.page),
		{
			enabled: isUserFeatureEnabled(user, 'asset_allocations'),
			staleTime: dayjs.duration(15, 'minutes').asMilliseconds(),
			...queryOptions,
		},
	);
};

type TUseFlyoutQueryData = TFlyoutResponse[];
