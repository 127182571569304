import { useTranslation, useMe, useState, useCallback } from '@hooks';
import type { TCommunity } from '@typings';
import { RadioGroupInput, FormSection, InputErrorMessage, Gap } from '@ui-kit';
import { useNetworkConnectionMemberRelationsQuery } from '@ui-modules/connections/hooks/useNetworkConnectionMemberRelationsQuery';
import { clsx, extractIdFromIri } from '@utils';
import type { FormikProps } from 'formik';
import type { IAutocompleteSearchUser } from '../../../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import UserSearchAutocomplete from '../../../../../../app/components/UserSearchAutocomplete/UserSearchAutocomplete';
import type { TNetworkConnectionFormValues } from '../../ConnectionForm.schema';
import { connectionFormFields } from '../../ConnectionForm.schema';
import styles from './UserSection.module.css';

const UserSection = ({
	isEdit,
	formProps,
	networkConnectionName,
	isUserSearchShown,
	communityId,
}: IUserSectionProps) => {
	const { t } = useTranslation();
	const { user, fullName: myFullName } = useMe();
	const [selectedUser, setSelectedUser] = useState<IAutocompleteSearchUser | null>(null);
	const canPostBehalfOf = user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_CHAIR'); // TODO GET INFO WHO CAN CREATE behalf of.
	const { values, errors, setFieldError, setFieldValue } = formProps;
	const { data: memberRelations = [] } = useNetworkConnectionMemberRelationsQuery(communityId, {
		select: (relations) => relations.map((relation) => ({ label: relation.title, value: relation.id })),
	});

	const onSelectUser = useCallback(
		(selectedUser: IAutocompleteSearchUser) => {
			setSelectedUser(selectedUser);
			if (selectedUser) {
				setFieldValue(connectionFormFields.CREATOR, {
					iriId: selectedUser.id,
					name: selectedUser.fullName,
				});
			} else {
				setFieldValue(
					connectionFormFields.CREATOR,
					canPostBehalfOf
						? undefined
						: {
								iriId: user['@id'],
								name: myFullName,
						  },
				);
			}
			setFieldError(connectionFormFields.CREATOR, undefined);
		},
		[myFullName, user],
	);

	return (
		<FormSection>
			{!!isUserSearchShown && (
				<div className={clsx(errors.creator?.iriId && styles.input_error)}>
					<UserSearchAutocomplete
						communityId={communityId}
						defaultValue={isEdit ? {
							fullName: values.creator.name,
							role: '',
							avatar: '',
							id: values.creator.iriId,
							enabled: true,
						} : selectedUser}
						disabled={!!isEdit}
						indicateDisabledDealsUsers
						indicateNetworkUsers
						isClearable
						isErrored={!!errors.creator?.iriId}
						label={t('Post on behalf of...')}
						placeholder={t('Type a name')}
						showSearchIcon
						onSelect={onSelectUser}
					/>
					{errors.creator?.iriId ? <InputErrorMessage text={errors.creator.iriId} /> : <Gap gap={24} />}
				</div>
			)}
			<RadioGroupInput
				backgroundColor="white"
				errorMessage={errors.relation}
				label={
					isUserSearchShown
						? t("{{name}}'s relation to this {{networkConnectionName}}", { networkConnectionName, name: values.creator?.name || t('User') })
						: t('Your ({{userName}}) relation to this {{networkConnectionName}}', {
								userName: values?.creator?.name,
								networkConnectionName,
						  })
				}
				labelType="bold"
				options={memberRelations}
				value={values.relation}
				onChange={(value) => {
					setFieldValue(connectionFormFields.RELATION, value);
					setFieldError(connectionFormFields.RELATION, undefined);
				}}
			/>
		</FormSection>
	);
};

interface IUserSectionProps {
	isEdit?: boolean;
	formProps: FormikProps<TNetworkConnectionFormValues>;
	networkConnectionName: string;
	isUserSearchShown?: boolean;
	communityId: TCommunity['id'];
}

export default UserSection;
