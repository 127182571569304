import { useMe } from '@hooks';
import { isUserFeatureEnabled, isUserFeatureDisabledByAdmin } from '@utils';

export const useDealsEnabled = () => {
	const { user } = useMe();
	const isInvestor = user.roles.includes('ROLE_INVESTOR');
	const isFeatureEnabled = isUserFeatureEnabled(user, 'invest');
	const isEnabledByUser = isFeatureEnabled && isInvestor;
	const isDisabledByAdmin = isUserFeatureDisabledByAdmin(user, 'invest');
	return { enabled: isEnabledByUser, isSettingsAvailable: isInvestor && !isDisabledByAdmin };
};
