import clsx from 'clsx';
import { useEffect, useState } from '@hooks';
import { ArrowLeftIcon, ArrowRightIcon } from '@ui-kit';

import styles from './FeatureSlider.module.css';

import Portal from '../Portal';

import type { ReactNode } from 'react';

const FeatureSlider: React.FC<IFeatureSliderProps> = ({
	slides,
	children,
	visible,
	showDivider = true,
	onSlideShown,
}) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	useEffect(() => {
		if (visible) onSlideShown?.(slides[currentSlide]);
	}, [visible, currentSlide]);

	const nextSlide = () => {
		setCurrentSlide((prev) => (prev + 1) % slides.length);
	};

	const prevSlide = () => {
		setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
	};

	const goToSlide = (index: number) => {
		setCurrentSlide(index);
	};

	return (
		<>
			{children}
			{visible && (
				<Portal>
					<div className={styles.backdrop}>
						<div className={styles.slider}>
							{currentSlide !== 0 && (
								<button
									className={clsx(styles.slider__paginator, styles.slider__paginatorLeft)}
									name="arrowLeft"
									onClick={prevSlide}
								>
									<ArrowLeftIcon fill="#333" />
								</button>
							)}
							<div className={clsx(styles.slider__slide)}>
								<div>{slides[currentSlide].header}</div>
								{showDivider && <div className={styles.slider__divider} />}
								<div>{slides[currentSlide].body}</div>
							</div>
							{currentSlide !== slides.length - 1 && (
								<button
									className={clsx(styles.slider__paginator, styles.slider__paginatorRight)}
									name="arrowRight"
									onClick={nextSlide}
								>
									<ArrowRightIcon fill="#333" />
								</button>
							)}
							<div className={styles.slider__dots}>
								{slides.map((_, index) => (
									<button
										aria-label={`dot-${index}`}
										className={clsx(styles.dot, { [styles.active]: index === currentSlide })}
										key={index}
										onClick={() => goToSlide(index)}
									/>
								))}
							</div>
						</div>
					</div>
				</Portal>
			)}
		</>
	);
};

export interface IFeatureSliderProps {
	slides: TSlide[];
	visible: boolean;
	children: ReactNode;
	showDivider?: boolean;
	onSlideShown?: (slide: TSlide) => void;
}

export type TSlide = {
	header: ReactNode;
	body: ReactNode;
	name: string;
};

export default FeatureSlider;
